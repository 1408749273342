import React from "react";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Typography } from "@material-ui/core";

const AvatarLineGroup = ({ max = 3, className, ...props }) => {
    const children = props.children.slice(0, max);

    return (
        <AvatarGroup max={max} className={className}>
            {children}
            {props.children.length > max 
            ? (
                <Typography variant="h5" style={{ marginLeft: 0, marginTop: 3 }}>+{props.children.length - max}</Typography>
            )
            : null}
        </AvatarGroup>
    );
};

export default AvatarLineGroup;
