import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles
} from "@material-ui/core";

import ContactShareEditorSelect from "./ContactShareEditorSelect";
import ContactShareEditorSplittingGM from "./ContactShareEditorSplittingGM";

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    width: spacing(50),
    height: spacing(60)
  }
}));

const ContactShareEditor = ({
  shares: initialShares,
  splitting: initialSplitting,
  contactCreatorId,
  isOpen,
  onClose,
  onSubmit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [shares, setShares] = useState(initialShares);
  const [splitting, setSplitting] = useState(undefined);
  const [users, setUsers] = useState();

  const handleSharesChange = value => setShares(value);
  const handleSplittingChange = value => setSplitting(value);

  const handleSubmitButtonClick = () => {
    if (onSubmit) {
      console.log(shares, splitting);
      onSubmit({ shares, splitting });
    }
  };

  useEffect(() => {
    setShares(initialShares);
    // setSplitting({ ownerShare: 100, userShare: 0 });
  }, [isOpen]);

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {t("webapp:contact.title.share")}
      </DialogTitle>
      <DialogContent>
        <ContactShareEditorSelect value={shares} contactCreatorId={contactCreatorId} onChange={handleSharesChange} />
        <ContactShareEditorSplittingGM value={splitting} onChange={handleSplittingChange} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("webapp:common.title.cancel")}
        </Button>
        <Button color="primary" onClick={handleSubmitButtonClick}>
          {t("webapp:common.title.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactShareEditor;
