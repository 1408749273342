import React from "react";

import {
  Button, Dialog, Typography, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, makeStyles
} from "@material-ui/core";

import { UserRole as PermissionRoles } from "@cargotic/model";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { UserInviteSchema } from "../../../utility/validationSchema";
import useAuth from "../../hook/useAuth";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    "p + p": {
      marginTop: "15px"
    }
  },
  select: {
    width: "50%",
    maxWidth: "50%",
    marginTop: "15px"
  },
  error: {
    color: "red"
  }
}));


const UserInviteDialog = ({
  open,
  handleSubmit,
  handleClose
}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const { user: { role } } = useAuth();

  const createPermissionMenuItems = () => {
    const items = Object.entries(PermissionRoles)
      .filter(([, value]) => value !== PermissionRoles.OWNER)
      .filter(([, value]) => {
        if (role === PermissionRoles.MANAGER) {
          return value !== PermissionRoles.MANAGER && value !== PermissionRoles.OWNER;
        }

        return value !== PermissionRoles.OWNER;
      });

    return items.map(([, value]) => (
      <MenuItem value={value} key={value}>
        {t(`users.roles.${value}`)}
      </MenuItem>
    ));
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {t("users.invite.title")}
        </DialogTitle>
        <Formik
          initialValues={{ email: "", firstName: "", lastName: "", role: "DISPATCHER" }}
          onSubmit={handleSubmit}
          validationSchema={UserInviteSchema}
        >
          {({
            values,
            handleChange,
            handleBlur,
            submitForm,
            touched,
            errors
          }) => (
              <>
                <DialogContent>
                  <form className={classes.root}>
                    <TextField
                      required
                      name="firstName"
                      label={t("users.invite.firstName")}
                      error={errors.firstName && touched.firstName}
                      helperText={touched.firstName && t(errors.firstName)}
                      value={values.firstName}
                      onChange={handleChange}
                      fullWidth
                      onBlur={handleBlur}
                    />
                    <TextField
                      required
                      name="lastName"
                      label={t("users.invite.lastName")}
                      error={errors.lastName && touched.lastName}
                      helperText={touched.lastName && t(errors.lastName)}
                      value={values.lastName}
                      onChange={handleChange}
                      fullWidth
                      onBlur={handleBlur}
                    />
                    <TextField
                      required
                      name="email"
                      label={t("users.invite.email")}
                      error={errors.email && touched.email}
                      helperText={touched.email && t(errors.email)}
                      value={values.email}
                      onChange={handleChange}
                      fullWidth
                      onBlur={handleBlur}
                    />
                    <Select
                      required
                      variant="outlined"
                      name="role"
                      className={classes.select}
                      label={t("users.invite.role")}
                      value={values.role}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {...(createPermissionMenuItems())}
                    </Select>
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    {t("abort")}
                  </Button>
                  <Button onClick={submitForm} color="primary">
                    {t("invite")}
                  </Button>
                </DialogActions>
              </>
            )}
        </Formik>
      </Dialog>
    </>
  );
};

export default UserInviteDialog;
