import React, { useState, useEffect } from "react";

import {
  Tabs,
  Tab,
  Fab,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  makeStyles,
  Slider,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Paper,
  Divider,
  MenuItem,
  Grid,
  Button,
  Tooltip,
  LinearProgress,
  IconButton,
  TextField
} from "@material-ui/core";
import { GoogleMap, withGoogleMap, DirectionsRenderer } from "react-google-maps";
import { formatDistance } from "date-fns";
import { cs, enUS } from "date-fns/locale";
import { ContactType, ShipmentType, ShipmentState, UserRole } from "@cargotic/model-deprecated";
import { ShipmentActivityType } from "@cargotic/model";
import { replaceIndex } from "@cargotic/common";
import { usePrevious } from "@cargotic/common-deprecated";

import { Currency, convertCurrency, useExchangeRates } from "@cargotic/currency-deprecated";
import { allowEmptyString, required, isObject } from "@cargotic/validate-deprecated";
import {
  deserializeFloat,
  deserializeInteger,
  identity,
  pipe,
  EditableTypography,
  ValueError,
  updateIndex,
  findIndex
} from "@cargotic/common-deprecated";

import {
  deleteShipmentDocument,
  updateShipmentById,
  uploadShipmentDocument
} from "@cargotic/api-client-deprecated";

import { Edit, AllInbox, Check, Queue, FastForward, DoubleArrow, Delete, Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import useAuth from "../../hook/useAuth";
import {
  ContactFormAutosuggestTextField,
  EmployeeFormAutosuggestTextField
} from "../../../contact";
import { createFormValidator, useForm, FormCheckbox, FormSelect, FormTextField } from "../../../form";
import MapTheme from "../../../../../multiload/cargotic-webapp/theme/CargoticMapTheme.json";
import ShipmentActivityTimeline from "../ShipmentActivityTimeline";

import {
  mapAndFormatWaypoints,
  getWaypointsWithCargo,
  parseShipmentState,
  getDrivenThroughAtText,
  getDrivenThroughText
} from "../../../utility/waypoint";

import ShipmentFormType from "../../../utility/ShipmentFormType";
import BoardCardWaypointIcon from "./component/BoardCardWaypointIcon";
import client from "../../../client";

import {
  useApiClient
} from "@cargotic/api-client-deprecated";

import { teal, grey, orange, blue, green, brown } from "@material-ui/core/colors";
import WaypointType from "../../../utility/WaypointType";

import CargoItemList from "../../../../../multiload/cargotic-component/component/CargoItemList"

import Shipments from "../Shipments/Shipments";
import {
  useApiClient as useNewApiClient,
  FormikVehicleAutosuggestTextField,
  FormUserAutosuggestTextField
} from "../../../../cargotic-webapp-component";

import { generateUuid, formatDateTime }
  from "../../../../../multiload/cargotic-common";

import FileDropzone
  from "../../../../../multiload/cargotic-webapp/component/FileDropzone";

import FileUpload
  from "../../../../../multiload/cargotic-webapp/component/FileUpload";

import { VehicleType } from "@cargotic/model";
import UpdateDriveThroughDialog from "./component/UpdateDriveThroughDialog";
import PersistentSnackbar from "../../../component/common/PersistentSnackbar";

const useStyles = makeStyles(({ spacing, palette }) => ({
  tabContentContainer: {
    paddingTop: spacing(1)
  },
  subStatusText: {
    fontSize: 28,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: spacing(3),
    textTransform: "uppercase"
  },
  markPoint: {
    height: 8,
    width: 1,
    marginTop: -3
  },
  containerMarginBottom: {
    marginBottom: spacing(2)
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)"
  },
  gutterBottom: {
    marginBottom: spacing(2)
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  isDrivenThrough: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  isDrivenThroughIcon: {
    marginLeft: 5,
    marginRight: 15,
    width: 18,
    height: 18
  },
  cardLabel: {
    borderRadius: 10,
    marginRight: 8,
    width: 50,
    height: 8
  },
  cardLabelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  labelsColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end"
  },
  infoHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  contactTable: {
    width: "98%"
  },
  priceRow: {
    "& > :not(:first-child)": {
      paddingLeft: spacing(2)
    }
  },
  waypointAddress: {
    marginLeft: "4px"
  }
}));

const INITAL_PAYMENT_DUE_DAYS = "60";

const BoardEditForm = ({
  shipment,
  shipments,
  setShipments,
  onSave
}) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { user, hasPermission } = useAuth();

  const {
    isLoading: isExchangeRatesLoading,
    exchangeRates
  } = useExchangeRates();
  const { enqueueSnackbar } = useSnackbar();

  const apiClient = useApiClient();
  const newApiClient = useNewApiClient();
  const classes = useStyles();
  const directionsService = new google.maps.DirectionsService();

  const [isDriveThroughLoading, setIsDriveThroughLoading] = useState(false);
  const [nextShipmentNumber, setNextShipmentNumber] = useState();
  const [updateDriveThroughDialogOpen, setUpdateDriveThroughDialogOpen] = useState(false);
  const [updatingWaypoint, setUpdatingWaypoint] = useState({});

  const [currentTabValue, setCurrentTabValue] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [stepValue, setStepValue] = useState(4);
  const [directions, setDirections] = useState([]);
  const [progress, setProgress] = useState(40);
  const [formType, setFormType] = useState(
    shipment.type === ShipmentType.CARRIED ? ShipmentFormType.CARRIER : ShipmentFormType.FORWARDER
  );
  const [activity, setActivity] = useState([]);
  const [
    hasDocumentFileDropzoneError,
    setHasDocumentFileDropzoneError
  ] = useState(false);

  const ErrorAction = {
    SUB: 0,
    NOP: 0,
    ADD: 0
  };

  const handleDocumentDelete = async (uuid, form) => {
    const { url } = form.values.documents
      .find(({ uuid: other }) => other === uuid);

    if (url) {
      try {
        await deleteShipmentDocument(apiClient, url);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(
          t("webapp:shipment.form.document.error.file.delete"),
          { variant: "error" }
        );

        return;
      }
    }

    form.setValues(values => ({
      ...values,
      documents: values.documents.filter(({ uuid: other }) => other !== uuid)
    }));
  };

  const handleDocumentFileDropzoneError = () =>
    setHasDocumentFileDropzoneError(true);

  const handleDocumentFilesSelect = (files, form) => {
    setHasDocumentFileDropzoneError(false);

    files.forEach(async (file) => {
      const uuid = generateUuid();
      const document = {
        uuid,
        name: file.name,
        isUploading: true
      };

      const updateDocument = update => form.setValues(values => ({
        ...values,
        documents: values.documents
          .map((other) => {
            if (other.uuid !== uuid) {
              return other;
            }

            return { ...other, ...update };
          })
      }));

      form.setValues((values) => ({
        ...values,
        documents: [...values.documents, document]
      }));

      try {
        const { url, createdAt, id } = await uploadShipmentDocument(apiClient, file);

        updateDocument({ id, url, createdAt, isUploading: false });
      } catch (error) {
        console.log(error);

        let message = t("webapp:shipment.form.document.error.upload.general");

        if (error?.response?.data?.error === "FileTooLargeError") {
          message = t("webapp:shipment.form.document.error.upload.size");
        }

        updateDocument({
          isUploading: false,
          error: message
        });
      }
    });
  };

  const fetchActivity = async (shipmentId) => {
    const activity = await client.shipment.getShipmentActivity({ shipmentId });
    setActivity(activity);
  };

  const steps = [ShipmentState.QUEUE, ShipmentState.READY, ShipmentState.IN_PROGRESS, ShipmentState.DONE];

  const loadNextShipmentNumber = async () => {
    if (shipment.isDraft) {
      const number = await newApiClient.shipment.getNextShipmentNumber();
      setNextShipmentNumber(number);
    }
  };

  useEffect(() => {
    loadNextShipmentNumber();
    fetchActivity(shipment.id);
  }, []);

  useEffect(() => {
    directionsService.route(
      {
        waypoints: shipment.journey.waypoints.slice(1, -1).map((val) => ({ location: new google.maps.LatLng(val.place.latitude, val.place.longitude) })),
        origin: { placeId: shipment.journey.waypoints[0].place.googleId },
        destination: { placeId: shipment.journey.waypoints[shipment.journey.waypoints.length - 1].place.googleId },
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.log(status);
          setDirections(null);
        }
      }
    );
    const currentStepIndex = steps.findIndex((step) => step === shipment.state);
    setActiveStep(currentStepIndex);
  }, []);

  const [formattedWaypoints, setFormattedWaypoints] = useState(mapAndFormatWaypoints(shipment.journey.waypoints, true, false));

  const handleChangeTab = (event, value) => setCurrentTabValue(value);
  const CZECH_REPUBLIC_POSITION = { lat: 49.743979, lng: 15.338667 };

  const calculateCommission = (
    customerPrice,
    customerPriceCurrency,
    carrierPrice,
    carrierPriceCurrency,
    commissionCurrency
  ) => {
    const parsedCustomerPrice = parseFloat(customerPrice);
    const parsedCarrierPrice = parseFloat(carrierPrice);

    if (
      Number.isNaN(parsedCustomerPrice)
      || Number.isNaN(parsedCarrierPrice)
    ) {
      return undefined;
    }

    const convertedCustomerPrice = convertCurrency(
      parsedCustomerPrice,
      customerPriceCurrency,
      commissionCurrency,
      exchangeRates
    );

    const convertedCarrierPrice = convertCurrency(
      parsedCarrierPrice,
      carrierPriceCurrency,
      commissionCurrency,
      exchangeRates
    );

    return convertedCustomerPrice - convertedCarrierPrice;
  };

  const DirectionsMap = withGoogleMap(({ directions }) => (
    <GoogleMap
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        styles: MapTheme
      }}
      defaultZoom={8}
      defaultCenter={CZECH_REPUBLIC_POSITION}
    >
      <DirectionsRenderer
        directions={directions}
      />
    </GoogleMap>
  ));

  const waypoints = getWaypointsWithCargo(shipment.journey.waypoints);

  const validateDeleted = (x, message) => {
    if (typeof x === "object" && x?.isDeleted) {
      throw new ValueError(message)
    }

    return x;
  }

  const validateContact = pipe(
    (contact) => required(contact, t("webapp:shipment.form.error.contact")),
    (contact) => isObject(contact, t("webapp:shipment.form.error.contact")),
    (contact) => validateDeleted(contact, t("webapp:shipment.form.error.deletedContact"))
  );

  const validateDraftContact = pipe(
    allowEmptyString((contact) => isObject(contact, t("webapp:shipment.form.error.contact"))),
    (contact) => validateDeleted(contact, t("webapp:shipment.form.error.deletedContact"))
  )

  const validateEmployee = pipe(
    allowEmptyString((employee) => isObject(employee, t("webapp:shipment.form.error.employee"))),
    (employee) => validateDeleted(employee, t("webapp:shipment.form.error.deletedEmployee"))
  );

  const isString = x => typeof x === "string";

  const validateDraftPrice = allowEmptyString(
    (x) => deserializeFloat(x, t("webapp:shipment.form.error.price"))
  );

  const validateDriver = pipe(
    (driver) => required(driver, t("webapp:shipment.form.error.driver")),
    (driver) => isObject(driver, t("webapp:shipment.form.error.driver")),
    (driver) => validateDeleted(driver, t("webapp:shipment.form.error.deletedDriver"))
  )

  const validateDraftDriver = pipe(
    allowEmptyString((driver) => isObject(driver, t("webapp:shipment.form.error.driver"))),
    (driver) => validateDeleted(driver, t("webapp:shipment.form.error.deletedDriver"))
  )

  const validateVehicle = pipe(
    (vehicle) => required(vehicle, t("webapp:shipment.form.error.vehicle")),
    (vehicle) => isObject(vehicle, t("webapp:shipment.form.error.vehicle")),
    (vehicle) => validateDeleted(vehicle, t("webapp:shipment.form.error.deletedVehicle"))
  )

  const validateDraftVehicle = pipe(
    allowEmptyString((vehicle) => isObject(vehicle, t("webapp:shipment.form.error.vehicle"))),
    (vehicle) => validateDeleted(vehicle, t("webapp:shipment.form.error.deletedVehicle"))
  )

  const validateTrailer = pipe(
    allowEmptyString((trailer) => isObject(trailer, t("webapp:shipment.form.error.trailer"))),
    (trailer) => validateDeleted(trailer, t("webapp:shipment.form.error.deletedTrailer"))
  )

  const validatePrice = pipe(
    (x) => required(x, t("webapp:shipment.form.error.price")),
    (x) => deserializeFloat(x, t("webapp:shipment.form.error.price"))
  );

  const validateOptionalString = allowEmptyString(identity);

  const validateSubmitDraft = ({ values }) => {
    let errors = {};

    // validate shared values for both forms
    try {
      validateDraftContact(values.customerContact);
    } catch (err) {
      errors = { ...errors, customerContact: err };
    }
    try {
      validateEmployee(values.customerEmployee);
    } catch (err) {
      errors = { ...errors, customerEmployee: err };
    }
    try {
      validateDraftPrice(values.customerPrice);
    } catch (err) {
      errors = { ...errors, customerPrice: err };
    }

    // validate forwarder part
    if (formType === ShipmentFormType.FORWARDER) {
      try {
        validateDraftContact(values.carrierContact);
      } catch (err) {
        errors = { ...errors, carrierContact: err };
      }
      try {
        validateEmployee(values.carrierEmployee);
      } catch (err) {
        errors = { ...errors, carrierEmployee: err };
      }
      try {
        validateDraftPrice(values.carrierPrice);
      } catch (err) {
        errors = { ...errors, carrierPrice: err };
      }
    }
    else {
      try {
        validateDraftVehicle(values.vehicle);
      } catch (err) {
        errors = { ...errors, vehicle: err };
      }
      try {
        validateDraftDriver(values.driver);
      } catch (err) {
        errors = { ...errors, driver: err };
      }
      try {
        validateTrailer(values.trailer);
      } catch (err) {
        errors = { ...errors, trailer: err };
      }
    }

    return errors;
  }

  const onSubmitDraft = async (form) => {
    try {
      const { values } = form;

      const errs = validateSubmitDraft(form);

      if (Object.keys(errs).length !== 0) {
        form.setErrors(errs);
        return;
      }

      await updateShipmentById(apiClient, shipment.id, {
        ...shipment,
        ...values,
        customerPrice: values.customerPrice === "" ? undefined : values.customerPrice,
        carrierPrice: values.carrierPrice === "" ? undefined : values.carrierPrice,
        customerPaymentDueDays: values.customerPaymentDueDays === "" ? undefined : values.customerPaymentDueDays,
        carrierPaymentDueDays: values.carrierPaymentDueDays === "" ? undefined : values.carrierPaymentDueDays,
        isDraft: true,
        state: ShipmentState.QUEUE,
        type: formType === ShipmentFormType.CARRIER ? ShipmentType.CARRIED : ShipmentType.FORWARDED,
        indexNumber: undefined,
        commission: undefined,
        commissionCurrency: undefined,
        journey: undefined
      });
      enqueueSnackbar(t("shipment.update.success"), { variant: "success" });
      onSave();
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("shipment.update.error.general"), { variant: "error" });
    }
  };

  const handleCommentarySubmit = async content => {
    try {
      const { id, createdAt } = await client.shipment.postShipmentCommentary({
        shipmentId: shipment.id,
        commentary: { content }
      });

      setActivity(current => ([
        ...current,
        {
          type: ShipmentActivityType.SHIPMENT_COMMENTARY,
          commentary: {
            id,
            author: { ...user, name: `${user.firstName} ${user.lastName}` },
            content,
            createdAt
          }
        }
      ]));
    } catch (error) {
      console.log(error);

      enqueueSnackbar("An error has occurred while posting your commentary!", {
        variant: "error"
      });
    }
  };

  const handleCommentaryChange = async (commentaryId, content) => {
    try {
      const { createdAt } = await client.shipment.putShipmentCommentary({
        shipmentId: shipment.id,
        commentaryId,
        commentary: {
          content
        }
      });

      setActivity(current => {
        const index = current
          .findIndex((other) => other.commentary?.id === commentaryId);

        const updated = replaceIndex(current, index, {
          type: ShipmentActivityType.SHIPMENT_COMMENTARY,
          commentary: {
            author: { ...user, name: `${user.firstName} ${user.lastName}` },
            id: commentaryId,
            content,
            createdAt
          }
        });

        return updated;
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar("An error has occurred while ", {
        variant: "error"
      });
    }
  };

  const onChangeDriveThrough = async (waypoint) => {
    setIsDriveThroughLoading(true);

    const data = await client.shipment.postShipmentWaypointDriveThrough({
      shipmentId: shipment.id,
      waypointId: waypoint.id
    });

    updateShipmentsDriveThrough({
      ...waypoint,
      isDrivenThrough: true,
      drivenThroughAt: data
    }, ErrorAction.SUB);

    fetchActivity(shipment.id);
    setIsDriveThroughLoading(false);
  }

  const handleCommentaryDelete = async commentaryId => {
    try {
      await client.shipment.deleteShipmentCommentary({
        shipmentId: shipment.id,
        commentaryId
      });

      setActivity(current => (
        current.filter((other) => other.commentary?.id !== commentaryId)
      ));
    } catch (error) {
      console.log(error);

      enqueueSnackbar("An error has occurred while deleting your commentary!", {
        variant: "error"
      });
    }
  };

  const getActivityTabContent = () => (
    <div className={classes.tabContentContainer}>
      {
        activity.length !== 0
          ? (
            <ShipmentActivityTimeline
              activity={activity}
              user={{
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                href: "@",
                avatarUrl: user.avatarUrl
              }}
              onCommentaryChange={handleCommentaryChange}
              onCommentaryDelete={handleCommentaryDelete}
              onCommentarySubmit={handleCommentarySubmit}
            />
          )
          : null
      }
    </div>
  );

  const updateWaypointDriveThrough = async (drivenThroughAt) => {
    setIsDriveThroughLoading(true);

    await client.shipment.putShipmentWaypointDriveThrough({ shipmentId: shipment.id, waypointId: updatingWaypoint.id, drivenThroughAt });
    updateShipmentsDriveThrough({
      ...updatingWaypoint,
      drivenThroughAt
    }, ErrorAction.NOP);
    setUpdateDriveThroughDialogOpen(false);

    setIsDriveThroughLoading(false);
    fetchActivity(shipment.id);
  }

  const updateShipmentsDriveThrough = ({
    id,
    action,
    index,
    formattedPlace,
    formattedDateTime,
    isDrivenThrough,
    drivenThroughAt,
    cargo
  }, errorAction) => {
    const newWaypoints = updateIndex(
      formattedWaypoints,
      index,
      {
        id,
        action,
        index,
        formattedPlace,
        formattedDateTime,
        isDrivenThrough: Boolean(drivenThroughAt),
        drivenThroughAt: drivenThroughAt,
        cargo
      },
    );
    setFormattedWaypoints(newWaypoints);

    const oldShipments = shipments[shipment.state].matches;
    const shipmentIndex = oldShipments.findIndex(({ id: shipmentId }) => shipmentId === shipment.id);
    const newShipments = updateIndex(
      oldShipments,
      shipmentIndex,
      {
        ...shipment,
        journey: {
          ...shipment.journey,
          waypoints: shipment.journey.waypoints.map((waypoint, index) => ({
            ...waypoint,
            isDrivenThrough: (waypoint.id === id) ? Boolean(drivenThroughAt) : newWaypoints[index].isDrivenThrough,
            drivenThroughAt: (waypoint.id === id) ? drivenThroughAt : newWaypoints[index].drivenThroughAt
          }))
        }
      }
    );

    const { arriveAtFrom, arriveAtTo } = shipment.journey.waypoints[index];
    const currentDate = new Date();
    const warning = errorAction * (arriveAtFrom < currentDate && arriveAtTo > currentDate);
    const error = errorAction * ((arriveAtTo ?? arriveAtFrom) < currentDate);

    setShipments({
      ...shipments,
      [shipment.state]: {
        total: shipments[shipment.state].total,
        totalWarnings: shipments[shipment.state].totalWarnings + warning,
        totalErrors: shipments[shipment.state].totalErrors + error,
        matches: newShipments
      }
    });
  }

  const deleteWaypointDriveThrough = async (waypoint) => {
    setIsDriveThroughLoading(true);

    await client.shipment.deleteShipmentWaypointDriveThrough({ shipmentId: shipment.id, waypointId: waypoint.id });

    updateShipmentsDriveThrough({
      ...waypoint,
      isDrivenThrough: false,
      drivenThroughAt: undefined
    }, ErrorAction.ADD);

    setIsDriveThroughLoading(false);
    fetchActivity(shipment.id);
  }

  const CardLabel = ({ title, color }) => (
    <div className={classes.cardLabelRow} >
      <Typography variant="subtitle1" style={{ marginRight: 15 }} >{title}</Typography>
      <span
        className={classes.cardLabel}
        style={{ backgroundColor: color }}
      />
    </div>
  );

  const getBasicInfoTabContent = () => (
    <div className={classes.tabContentContainer}>
      <section className={classes.gutterBottom}>
        <div className={classes.infoHeader} >
          <div>
            <Typography className={classes.subtitle} gutterBottom>{t("board.indexNumber")}</Typography>
            <Typography style={{ fontSize: 20, fontWeight: 300 }} gutterBottom>{shipment.indexNumber ? `#${shipment.indexNumber}` : t("board.draft")}</Typography>
          </div>
          <div className={classes.labelsColumn} >
            {shipment.isDraft
              ? <CardLabel color={orange[400]} title={t("board.labels.draft")} />
              : null}
            {shipment.type === "FORWARDED"
              ? <CardLabel color={blue[400]} title={t("board.labels.forwarded")} />
              : <CardLabel color={brown[500]} title={t("board.labels.carried")} />
            }
            {shipment.paidAt
              ? <CardLabel color={green[400]} title={t("board.labels.paid")} />
              : null}
          </div>
        </div>
        <Typography className={classes.subtitle} gutterBottom>{t("board.progress")}</Typography>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{parseShipmentState(label, t)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </section>
      <Divider className={classes.divider} />
      <section className={classes.gutterBottom}>
        <Typography className={classes.subtitle} gutterBottom>{t("board.waypoints")}</Typography>
        <Grid container direction="column" spacing={1} >
          {formattedWaypoints.map((waypoint) => {
            const {
              placeAlias,
              placeName,
              action,
              formattedPlace,
              formattedDateTime,
              isDrivenThrough,
              drivenThroughAt,
              cargo
            } = waypoint;
            return (
              <div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                <Grid container item alignContent="center" alignItems="center" >
                  <Grid item xs={5}>
                    <Typography className={classes.waypointAddress} variant="body2"><i>{placeAlias ?? placeName}</i></Typography>
                    <Typography className={classes.waypointAddress} variant="body2"><b>{formattedPlace}</b></Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">{formattedDateTime}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isDrivenThrough ?
                      <div
                        className={classes.isDrivenThrough} >
                        <Check
                          className={classes.isDrivenThroughIcon}
                          style={{ color: teal[700] }} />
                        <Typography
                          style={{ marginRight: 10 }}
                          variant="body2" >
                          {getDrivenThroughAtText(t, action, drivenThroughAt)}
                        </Typography>
                        <IconButton size="small" disabled={isDriveThroughLoading} onClick={() => {
                          setUpdatingWaypoint(waypoint);
                          setUpdateDriveThroughDialogOpen(true);
                        }} >
                          <Edit color="action" />
                        </IconButton>
                        <IconButton
                          size="small"
                          disabled={isDriveThroughLoading}
                          onClick={() => deleteWaypointDriveThrough(waypoint)} >
                          <Close color="action" />
                        </IconButton>
                      </div>
                      : !shipment.isDraft ? <Button
                        variant="contained"
                        color="primary"
                        disabled={isDriveThroughLoading}
                        onClick={() => onChangeDriveThrough(waypoint)}>
                        {getDrivenThroughText(t, action)}
                      </Button> : null
                    }
                  </Grid>
                  <CargoItemList cargo={cargo} />
                </Grid>
              </div>
            )
          }
          )}
        </Grid>
      </section>
      <Divider className={classes.divider} />
      <section className={classes.gutterBottom}>
        <Typography className={classes.subtitle} gutterBottom>{t("shipments.cargo")}</Typography>
        {waypoints.length <= 0
          ? (
            <Typography variant="subtitle1">{t("board.noCargo")}</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="cargo-table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("shipments.description")}</TableCell>
                    <TableCell>{t("shipments.amount")}</TableCell>
                    <TableCell>{t("shipments.unit")}</TableCell>
                    <TableCell>{t("shipments.length")}</TableCell>
                    <TableCell>{t("shipments.width")}</TableCell>
                    <TableCell>{t("shipments.height")}</TableCell>
                    <TableCell>{t("shipments.weight")}</TableCell>
                    <TableCell>{t("shipments.volume")}</TableCell>
                    <TableCell>{t("shipments.totalWeight")}</TableCell>
                    <TableCell>{t("shipments.totalVolume")}</TableCell>
                    <TableCell><Tooltip title={t("shipments.stackability")}><AllInbox /></Tooltip></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {waypoints.map((cargo) => (
                    <TableRow key={cargo.id}>
                      <TableCell><Typography>{cargo.description}</Typography></TableCell>
                      <TableCell><Typography>{cargo.quantity}</Typography></TableCell>
                      <TableCell><Typography>{t(`core:cargo.item.template.${cargo.template}`)}</Typography></TableCell>
                      <TableCell><Typography>{cargo.length ? `${cargo.length} ${cargo.lengthUnit.toLowerCase()}` : null}</Typography></TableCell>
                      <TableCell><Typography>{cargo.width ? `${cargo.width} ${cargo.lengthUnit.toLowerCase()}` : null}</Typography></TableCell>
                      <TableCell><Typography>{cargo.height ? `${cargo.height} ${cargo.lengthUnit.toLowerCase()}` : null}</Typography></TableCell>
                      <TableCell><Typography>{cargo.weight ? `${cargo.weight} kg` : null}</Typography></TableCell>
                      <TableCell><Typography>{cargo.volume ? `${cargo.volume} cbm` : null}</Typography></TableCell>
                      <TableCell><Typography>{cargo.totalWeight ? `${cargo.totalWeight} kg` : null}</Typography></TableCell>
                      <TableCell><Typography>{cargo.totalVolume ? `${cargo.totalVolume} cbm` : null}</Typography></TableCell>
                      <TableCell><Typography>{cargo.isStackable ? <Tooltip title={t("shipments.stackable")}><Check /></Tooltip> : null}</Typography></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }
      </section>
      <Divider className={classes.divider} />
      <section className={classes.gutterBottom}>
        {getBasicInfoContent()}
      </section>
    </div >
  );

  const onShipmentSave = async (updatedShipment) => {
    try {
      await updateShipmentById(apiClient, updatedShipment.id, updatedShipment);
      enqueueSnackbar(t("shipment.update.success"), { variant: "success" });
      onSave();
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("shipment.update.error.general"), { variant: "error" });
    }
  };

  const forwarderForm = useForm({
    initialValues: {
      customerContact: shipment.customerContact ?? "",
      customerPrice: shipment.customerPrice ?? "",
      customerPriceCurrency: shipment.customerPriceCurrency ?? Currency.CZK,
      customerEmployee: shipment.customerEmployee ?? "",
      isCustomerPriceWithDph: shipment.isCustomerPriceWithDph,
      carrierContact: shipment.carrierContact ?? "",
      driverContact: shipment.driverContact,
      carrierPrice: shipment.carrierPrice ?? "",
      carrierPriceCurrency: shipment.carrierPriceCurrency ?? Currency.CZK,
      carrierEmployee: shipment.carrierEmployee ?? "",
      isCarrierPriceWithDph: shipment.isCarrierPriceWithDph,
      type: ShipmentType.FORWARDED,
      documents: shipment.documents ? shipment.documents.map((document) => ({ ...document, uuid: generateUuid() })) : []
    },
    validate: createFormValidator({
      driverContact: validateOptionalString,
      customerContact: validateContact,
      customerEmployee: validateEmployee,
      customerPrice: validatePrice,
      carrierContact: validateContact,
      carrierPrice: validatePrice,
      carrierEmployee: validateEmployee
    }),
    onChange: ({
      customerPrice,
      carrierPrice,
      carrierContact: newCarrierContact,
      customerContact: newCustomerContact,
      customerPriceCurrency: newCustomerPriceCurrency,
      carrierPriceCurrency: newCarrierPriceCurrency
    }, {
      carrierContact: oldCarrierContact,
      customerContact: oldCustomerContact,
      customerPriceCurrency: oldCustomerPriceCurrency,
      carrierPriceCurrency: oldCarrierPriceCurrency
    }) => {
      if (newCustomerPriceCurrency !== oldCustomerPriceCurrency) {
        const parsedCustomerPrice = parseFloat(customerPrice);

        if (Number.isNaN(parsedCustomerPrice)) {
          return;
        }

        forwarderForm.setValue(
          "customerPrice",
          parsedCustomerPrice
        );
      }

      if (newCarrierPriceCurrency !== oldCarrierPriceCurrency) {
        const parsedCarrierPrice = parseFloat(carrierPrice);

        if (Number.isNaN(parsedCarrierPrice)) {
          return;
        }

        forwarderForm.setValue(
          "carrierPrice",
          parsedCarrierPrice
        );
      }

      if (newCarrierContact !== oldCarrierContact) {
        forwarderForm.setValue("carrierContact", newCarrierContact);

        if (newCarrierContact === undefined || isString(newCarrierContact)) {
          return;
        }
      }

      if (newCustomerContact !== oldCustomerContact) {
        forwarderForm.setValue("customerContact", newCustomerContact);

        if (newCustomerContact === undefined || isString(newCarrierContact)) {
          return;
        }
      }
    },
    onSubmit: (values) => {
      onShipmentSave({
        ...shipment,
        ...values,
        customerPrice: values.customerPrice === "" ? undefined : values.customerPrice,
        carrierPrice: values.carrierPrice === "" ? undefined : values.carrierPrice,
        customerPaymentDueDays: values.customerPaymentDueDays === "" ? undefined : values.customerPaymentDueDays,
        carrierPaymentDueDays: values.carrierPaymentDueDays === "" ? undefined : values.carrierPaymentDueDays,
        indexNumber: shipment.isDraft
          ? nextShipmentNumber
          : shipment.indexNumber,
        state: shipment.isDraft ? ShipmentState.READY : shipment.state,
        commission: calculateCommission(
          forwarderForm.values.customerPrice,
          forwarderForm.values.customerPriceCurrency,
          forwarderForm.values.carrierPrice,
          forwarderForm.values.carrierPriceCurrency,
          Currency.CZK
        ),
        commissionCurrency: Currency.CZK,
        documents: values.documents
          .filter(({ id }) => id)
          .map(({ id }) => ({ id })),
        isDraft: false,
        journey: undefined,
        paidAt: undefined
      });
    }
  });

  const carrierForm = useForm({
    initialValues: {
      customerContact: shipment.customerContact ?? "",
      customerPrice: shipment.customerPrice ?? "",
      customerPriceCurrency: shipment.customerPriceCurrency ?? Currency.CZK,
      customerEmployee: shipment.customerEmployee ?? "",
      isCustomerPriceWithDph: shipment.isCustomerPriceWithDph,
      driver: shipment.driver ?? "",
      vehicle: shipment.vehicle ?? "",
      trailer: shipment.trailer ?? "",
      type: ShipmentType.CARRIED,
      documents: shipment.documents ? shipment.documents.map((document) => ({ ...document, uuid: generateUuid() })) : [],
      driverContact: shipment.driverContact ?? ""
    },
    validate: createFormValidator({
      customerContact: validateContact,
      customerEmployee: validateEmployee,
      customerPrice: validatePrice,
      driver: validateDriver,
      vehicle: validateVehicle,
      trailer: validateTrailer
    }),
    onChange: ({
      customerPrice,
      customerContact: newCustomerContact,
      currency: newCurrency
    }, {
      currency: oldCurrency,
      customerContact: oldCustomerContact
    }) => {
      if (newCustomerContact !== oldCustomerContact) {
        carrierForm.setValue("customerContact", newCustomerContact);
      }

      if (oldCurrency !== newCurrency) {
        const parsedCustomerPrice = parseFloat(customerPrice);

        if (!Number.isNaN(parsedCustomerPrice)) {
          carrierForm.setValue(
            "customerPrice",
            newCurrency
          );
        }
      }
    },
    onSubmit: (values) => {
      onShipmentSave({
        ...shipment,
        ...values,
        customerPrice: values.customerPrice === "" ? undefined : values.customerPrice,
        carrierPrice: values.carrierPrice === "" ? undefined : values.carrierPrice,
        indexNumber: shipment.isDraft
          ? nextShipmentNumber
          : shipment.indexNumber,
        state: shipment.isDraft ? ShipmentState.READY : shipment.state,
        commission: calculateCommission(
          forwarderForm.values.customerPrice,
          forwarderForm.values.customerPriceCurrency,
          forwarderForm.values.carrierPrice,
          forwarderForm.values.carrierPriceCurrency,
          Currency.CZK
        ),
        commissionCurrency: Currency.CZK,
        documents: values.documents
          .filter(({ id }) => id)
          .map(({ id }) => ({ id })),
        isDraft: false,
        journey: undefined,
        paidAt: undefined
      });
    }
  });

  const fetchContact = async (contactId) => newApiClient.contact.getContact({ contactId });

  const resolveFullContact = async (form, type, id) => {
    const fetchedContact = await fetchContact(id);
    form.setValue(type, fetchedContact);

    if (fetchedContact.paymentDueDays) {
      type === "customerContact"
        ? form.setValue("customerPaymentDueDays", fetchedContact.paymentDueDays)
        : form.setValue("carrierpaymentDueDays", fetchedContact.paymentDueDays)
    }
    else {
      type === "customerContact"
        ? form.setValue("carrierPaymentDueDays", INITAL_PAYMENT_DUE_DAYS)
        : form.setValue("carrierPaymentDueDays", INITAL_PAYMENT_DUE_DAYS)
    }


  }

  const previousForwarderValues = usePrevious(forwarderForm.values);
  const previousCarrierValues = usePrevious(carrierForm.values);

  const handleVehicleChange = async () => {
    const { driver } = carrierForm.values;
    let { vehicle } = carrierForm.values;

    if (typeof vehicle === "string") {
      return;
    }

    if (typeof vehicle === "object" && vehicle?.id !== previousCarrierValues?.vehicle?.id) {
      vehicle = await client.vehicle.getVehicle({ vehicleId: vehicle.id });

      carrierForm.setValue("vehicle", vehicle);
    }

    if (vehicle?.defaultDriver) {
      carrierForm.setValue("driver", vehicle.defaultDriver);
    }

    if (vehicle?.defaultTrailer) {
      carrierForm.setValue("trailer", vehicle.defaultTrailer);
    }
  };

  useEffect(() => {
    const { values: { customerContact } } = forwarderForm;
    const { customerContact: oldCustomerContact } = previousForwarderValues ?? {};

    if (!customerContact || (oldCustomerContact && oldCustomerContact?.id !== customerContact?.id)) {
      forwarderForm.setValue("customerEmployee", "");
    }

    // business address is only field that is different from Contact and ContactReference for sure
    if (customerContact?.billingAddress || typeof customerContact === "string") {
      return;
    }

    resolveFullContact(forwarderForm, "customerContact", customerContact.id);
  }, [forwarderForm.values.customerContact])

  useEffect(() => {
    const { values: { carrierContact } } = forwarderForm;
    const { carrierContact: oldCarrierContact } = previousCarrierValues ?? {};

    if (!carrierContact || (oldCarrierContact && oldCarrierContact?.id !== carrierContact?.id)) {
      forwarderForm.setValue("carrierEmployee", "");
    }

    // business address is only field that is different from Contact and ContactReference for sure
    if (carrierContact?.billingAddress || typeof carrierContact === "string") {
      return;
    }

    resolveFullContact(forwarderForm, "carrierContact", carrierContact.id);
  }, [forwarderForm.values.carrierContact])


  useEffect(() => {
    const { values: { customerContact } } = carrierForm;
    const { customerContact: oldCustomerContact } = previousCarrierValues ?? {};

    if (!customerContact || (oldCustomerContact && oldCustomerContact?.id !== customerContact?.id)) {
      carrierForm.setValue("customerEmployee", "");
    }

    // business address is only field that is different from Contact and ContactReference for sure
    if (customerContact?.billingAddress || typeof customerContact === "string") {
      return;
    }

    resolveFullContact(carrierForm, "customerContact", customerContact.id);
  }, [carrierForm.values.customerContact])

  useEffect(() => {
    handleVehicleChange();
  }, [carrierForm.values.vehicle]);

  const ForwarderFormComponent = (form) => (
    <>
      <section>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>
              {t("webapp:shipment.form.subtitle.customer")}
            </Typography>
            <Grid container className={classes.contactTable} >
              <Grid item xs={12}>
                <ContactFormAutosuggestTextField
                  form={form}
                  contactType={ContactType.CUSTOMER}
                  apiClient={newApiClient}
                  name="customerContact"
                  label={t("webapp:shipment.form.label.contact")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <EmployeeFormAutosuggestTextField
                  form={form}
                  apiClient={newApiClient}
                  contactId={form.values.customerContact?.id}
                  disabled={!form.values.customerContact?.id}
                  name="customerEmployee"
                  label={t("webapp:shipment.form.label.employee")}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container className={classes.priceRow}>
              <Grid item xs={4}>
                <FormTextField
                  form={form}
                  name="customerPrice"
                  label={t("webapp:shipment.form.label.price")}
                  hasFullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormSelect
                  form={form}
                  name="customerPriceCurrency"
                  label={t("webapp:shipment.form.label.currency")}
                  hasFullWidth
                >
                  {
                    Object.values(Currency).map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))
                  }
                </FormSelect>
              </Grid>
              <Grid item xs={4}>
                <FormCheckbox
                  form={form}
                  name="isCustomerPriceWithDph"
                  label={t("webapp:shipment.form.label.isWithDph")}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>
              {t("webapp:shipment.form.subtitle.carrier")}
            </Typography>
            <Grid container className={classes.contactTable}>
              <Grid item xs={12}>
                <ContactFormAutosuggestTextField
                  form={form}
                  apiClient={newApiClient}
                  contactType={ContactType.CARRIER}
                  name="carrierContact"
                  label={t("webapp:shipment.form.label.contact")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <EmployeeFormAutosuggestTextField
                  form={form}
                  apiClient={newApiClient}
                  contactId={form.values.carrierContact?.id}
                  disabled={!form.values.carrierContact?.id}
                  name="carrierEmployee"
                  label={t("webapp:shipment.form.label.employee")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} >
                <FormTextField
                  form={form}
                  name="driverContact"
                  label={t("webapp:shipment.form.label.driverContact")}
                  hasFullWidth
                />
              </Grid>
            </Grid>
            <Grid container className={classes.priceRow}>
              <Grid item xs={4}>
                <FormTextField
                  form={form}
                  name="carrierPrice"
                  label={t("webapp:shipment.form.label.price")}
                  hasFullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormSelect
                  form={form}
                  name="carrierPriceCurrency"
                  label={t("webapp:shipment.form.label.currency")}
                  hasFullWidth
                >
                  {
                    Object.values(Currency).map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))
                  }
                </FormSelect>
              </Grid>
              <Grid item xs={4}>
                <FormCheckbox
                  form={form}
                  name="isCarrierPriceWithDph"
                  label={t("webapp:shipment.form.label.isWithDph")}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <Divider className={classes.divider} />
      <section>
        <Typography className={classes.subtitle}>
          {t("webapp:shipment.form.subtitle.documents")}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <FileDropzone
              accept={["application/pdf", "image/jpeg", "image/png"]}
              title={
                hasDocumentFileDropzoneError
                  ? t("webapp:shipment.form.document.error.file.type")
                  : t("webapp:shipment.form.document.title.upload")
              }
              description={[
                t("webapp:shipment.form.document.description.about"),
                t("webapp:shipment.form.document.description.supported"),
                t("webapp:shipment.form.document.description.limit")
              ]}
              hasError={hasDocumentFileDropzoneError}
              onError={handleDocumentFileDropzoneError}
              onSelect={(files) => handleDocumentFilesSelect(files, form)}
            />
          </Grid>
          {
            form.values.documents.map(({ uuid, name, url, error, isUploading, createdAt }, index) => (
              <React.Fragment key={uuid}>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <FileUpload
                    className={index === 0 ? classes.upload : undefined}
                    title={name}
                    description={
                      createdAt
                        ? formatDateTime(createdAt)
                        : undefined
                    }
                    url={url}
                    isUploading={isUploading}
                    error={error}
                    onDelete={() => handleDocumentDelete(uuid, form)}
                  />
                </Grid>
              </React.Fragment>
            ))
          }
        </Grid>
      </section>
    </>
  );

  const CarrierFormComponent = (form) => (
    <>
      <section>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>
              {t("webapp:shipment.form.subtitle.customer")}
            </Typography>
            <Grid container className={classes.contactTable}>
              <Grid item xs={12}>
                <ContactFormAutosuggestTextField
                  form={form}
                  contactType={ContactType.CUSTOMER}
                  apiClient={newApiClient}
                  name="customerContact"
                  label={t("webapp:shipment.form.label.contact")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <EmployeeFormAutosuggestTextField
                  form={form}
                  apiClient={newApiClient}
                  contactId={form.values.customerContact?.id}
                  disabled={!form.values.customerContact?.id}
                  name="customerEmployee"
                  label={t("webapp:shipment.form.label.employee")}
                  fullWidth
                />
              </Grid>
              <Grid container className={classes.priceRow}>
                <Grid item xs={4}>
                  <FormTextField
                    form={form}
                    name="customerPrice"
                    label={t("webapp:shipment.form.label.price")}
                    hasFullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormSelect
                    form={form}
                    name="customerPriceCurrency"
                    label={t("webapp:shipment.form.label.currency")}
                    hasFullWidth
                  >
                    {
                      Object.values(Currency).map((currency) => (
                        <MenuItem key={currency} value={currency}>
                          {currency}
                        </MenuItem>
                      ))
                    }
                  </FormSelect>
                </Grid>
                <Grid item xs={4}>
                  <FormCheckbox
                    form={form}
                    name="isCustomerPriceWithDph"
                    label={t("webapp:shipment.form.label.isWithDph")}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.contactTable}>
            <Typography className={classes.subtitle}>
              {t("webapp:shipment.form.subtitle.shipping")}
            </Typography>
            <Grid container className={classes.contactTable}>
              <Grid item xs={12}>
                <FormikVehicleAutosuggestTextField
                  form={form}
                  name="vehicle"
                  type={VehicleType.CAR}
                  label={t("webapp:shipment.form.label.vehicle")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormikVehicleAutosuggestTextField
                  form={form}
                  name="trailer"
                  type={VehicleType.TRAILER}
                  label={t("webapp:shipment.form.label.trailer")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormUserAutosuggestTextField
                  form={form}
                  role="DRIVER"
                  name="driver"
                  label={t("webapp:shipment.form.label.driver")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <Divider className={classes.divider} />
      <section>
        <Typography className={classes.subtitle}>
          {t("webapp:shipment.form.subtitle.documents")}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <FileDropzone
              accept={["application/pdf", "image/jpeg", "image/png"]}
              title={
                hasDocumentFileDropzoneError
                  ? t("webapp:shipment.form.document.error.file.type")
                  : t("webapp:shipment.form.document.title.upload")
              }
              description={[
                t("webapp:shipment.form.document.description.about"),
                t("webapp:shipment.form.document.description.supported"),
                t("webapp:shipment.form.document.description.limit")
              ]}
              hasError={hasDocumentFileDropzoneError}
              onError={handleDocumentFileDropzoneError}
              onSelect={(files) => handleDocumentFilesSelect(files, form)}
            />
          </Grid>
          {
            form.values.documents.map(({ uuid, name, url, error, isUploading, createdAt }, index) => (
              <React.Fragment key={uuid}>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <FileUpload
                    className={index === 0 ? classes.upload : undefined}
                    title={name}
                    description={
                      createdAt
                        ? formatDateTime(createdAt)
                        : undefined
                    }
                    url={url}
                    isUploading={isUploading}
                    error={error}
                    onDelete={() => handleDocumentDelete(uuid, form)}
                  />
                </Grid>
              </React.Fragment>
            ))
          }
        </Grid>
      </section>
    </>
  );

  const saveShipment = formType === ShipmentFormType.CARRIER
    ? carrierForm.handleSubmit
    : forwarderForm.handleSubmit;

  const getBasicInfoContent = () => (
    <form>
      {shipment.type === ShipmentType.FORWARDED ? ForwarderFormComponent(forwarderForm) : CarrierFormComponent(carrierForm)}
      <div style={{ marginTop: 20 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!shipment.isDraft}
          onClick={() => onSubmitDraft(formType === ShipmentFormType.CARRIER ? carrierForm : forwarderForm)}
          style={{ marginRight: 5 }}
        >
          {t("webapp:shipment.form.button.draft")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={saveShipment}>
          {t("webapp:common.title.save")}
        </Button>
      </div>
    </form>
  );

  const getRouteAndCargoContent = () => (
    <div>
      <div className={classes.containerMarginBottom}>
        <Typography className={classes.subtitle} gutterBottom>{t("shipments.route")} {shipment.journey.distance && shipment.journey.duration ? `/ ${(shipment.journey.distance / 1000).toFixed() + " km"} / ${formatDistance(0, shipment.journey.duration * 1000, {
          includeSeconds: true,
          locale: t("locale") === "cs" ? cs : enUS
        })}` : null}
        </Typography>
        <DirectionsMap
          directions={directions}
          containerElement={<div style={{ height: "75vh", width: "100%" }} />}
          mapElement={<div style={{ height: "100%" }} />}
        />
      </div>
    </div >
  );

  const getTabContent = (value) => {
    switch (value) {
      case 0:
        return getBasicInfoTabContent();
      case 1:
        return getActivityTabContent();
      case 2:
        return getRouteAndCargoContent();
      default:
        throw new Error("Wrong tab value");
    }
  };

  return (
    <>
      <Page
        actions={
          <Fab
            color="primary"
            size="medium"
            onClick={() => history.push(`/shipment/${shipment.id}`)}
          >
            <Edit />
          </Fab>
        }>
        <Tabs value={currentTabValue} onChange={handleChangeTab} aria-label="tabs-detail-shipment" centered>
          <Tab value={0} label={t("board.basicInfo")} />
          <Tab value={1} label={t("board.activity")} />
          <Tab value={2} label={t("board.route")} />
        </Tabs>
        <div>
          {getTabContent(currentTabValue)}
        </div>
        <UpdateDriveThroughDialog
          drivenThroughAt={updatingWaypoint.drivenThroughAt}
          isOpen={updateDriveThroughDialogOpen && updatingWaypoint.drivenThroughAt}
          handleSubmit={updateWaypointDriveThrough}
          handleClose={() => setUpdateDriveThroughDialogOpen(false)}
        />
      </Page>
    </>
  );
};

export default BoardEditForm;
