import Axios from "axios";

enum Currency {
  CZK = "CZK",
  EUR = "EUR"
}

interface ExchangeRate {
  from: Currency;
  to: Currency;
  value: number;
}
interface ExchangeRates {
  rates: Array<ExchangeRate> ;
}

const EXCHANGE_RATES_API_URL = "https://api.exchangerate.host/convert";
const EXCHNAGE_RATE_API_KEY = "ee803b01fe1c26e46d8c70735cfbc491";

const fetchExchangeRate = async (from, to): Promise<ExchangeRate> => {
  const result = await Axios.get(EXCHANGE_RATES_API_URL,
    {
      params: {
        from, to, amount: 1, access_key: EXCHNAGE_RATE_API_KEY
      }
    });

  if (!result || !result.data || !result.data.success || !result.data.result) {
    throw new Error("Exchanges couldnt be gathered!");
  }

  return {
    from,
    to,
    value: result.data.result
  };
};

async function fetchExchangeRates(
  baseCurrency: Currency
): Promise<ExchangeRate[]> {
  const rates = await Promise.all([[Currency.CZK, Currency.EUR], [Currency.EUR, Currency.CZK]].map((pair) => fetchExchangeRate(pair[0], pair[1])));
  return [
    ...rates,
    { from: Currency.CZK, to: Currency.CZK, value: 1 },
    { from: Currency.EUR, to: Currency.EUR, value: 1 }
  ];
}

function convertCurrency(
  value: number,
  sourceCurrency: Currency,
  targetCurrency: Currency,
  exchangeRates: ExchangeRate[]
): number {
  if (sourceCurrency === targetCurrency) {
    return value;
  }

  const rate = exchangeRates.find(({ from, to }) => from === sourceCurrency && to === targetCurrency);
  // const { base, rates } = exchangeRates;

  // if (sourceCurrency === base) {
  //   return value * rates[targetCurrency];
  // }

  // if (targetCurrency === base) {
  //   return value / rates[sourceCurrency];
  // }

  return value * rate.value;
}

export {
  Currency,
  // ExchangeRates,

  convertCurrency,
  fetchExchangeRates
};
