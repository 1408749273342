import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Currency, convert, replaceIndex } from "@cargotic/common";
import { CircularProgress, makeStyles } from "@material-ui/core";

import { useExchangeRates } from "../../../cargotic-currency";
import useAuth from "../../../cargotic-webapp/component/hook/useAuth";
import { useApiClient } from "../../../cargotic-webapp-component";
import VehicleExpenseOverview from "../VehicleExpenseOverview";

const useStyles = makeStyles(() => ({
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const VehicleExpenseOverviewContainer = ({
  className,
  vehicle: {
    id,
    manufacturer,
    model
  },
  lastYear = new Date().getFullYear(),
  onBreadcrumbsChange
}) => {
  const client = useApiClient();
  const { hasPermission } = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    isLoading: areExchangeRatesLoding,
    exchangeRates
  } = useExchangeRates();

  const { enqueueSnackbar } = useSnackbar();
  const [yearlyExpenses, setYearlyExpenses] = useState();
  const [previousMonthlyExpenses, setPreviousMonthlyExpenses] = useState();
  const [selectedYear, setSelectedYear] = useState(lastYear);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedMonthlyExpenses, setSelectedMonthlyExpenses] = useState();
  const [
    isMonthlyExpenseEditorOpen,
    setIsMonthlyExpenseEditorOpen
  ] = useState(false);

  const [
    isMonthlyExpenseDeleteAlertOpen,
    setIsMonthlyExpenseDeleteAlertOpen
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const firstYear = 2019;
  const defaultMonthlyExpenses = {
    fixed: [
      { label: t("webapp:vehicle.expense.insurance"), value: "", currency: Currency.CZK },
      { label: t("webapp:vehicle.expense.roadTax"), value: "", currency: Currency.CZK },
      { label: t("webapp:vehicle.expense.leasing"), value: "", currency: Currency.CZK },
      { label: t("webapp:vehicle.expense.other"), value: "", currency: Currency.CZK }
    ],
    variable: [
      { label: t("webapp:vehicle.expense.driverPay"), value: "", currency: Currency.CZK },
      { label: t("webapp:vehicle.expense.fuel"), value: "", currency: Currency.CZK },
      { label: t("webapp:vehicle.expense.toll"), value: "", currency: Currency.CZK },
      { label: t("webapp:vehicle.expense.tires"), value: "", currency: Currency.CZK },
      { label: t("webapp:vehicle.expense.other"), value: "", currency: Currency.CZK }
    ]
  };

  const calculateRateOfChange = (
    { total: firstTotal },
    { total: secondTotal }
  ) => {
    if (secondTotal === 0) {
      return 0;
    }

    return (
      (firstTotal - secondTotal) / secondTotal
    );
  };

  const handleMonthlyExpenseDeleteAlertClose = () => (
    setIsMonthlyExpenseDeleteAlertOpen(false)
  );

  const handleMonthlyExpenseDeleteAlertOpen = () => (
    setIsMonthlyExpenseDeleteAlertOpen(true)
  );

  const handleMonthlyExpenseDeleteAlertSubmit = async () => {
    setIsMonthlyExpenseDeleteAlertOpen(false);

    let updatedYearlyExpenses = yearlyExpenses;

    try {
      await client.vehicle.deleteMonthlyVehicleExpenses({
        vehicleId: id,
        year: selectedYear,
        month: selectedMonth
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.deleteExpenses"), {
        variant: "error"
      });
    }

    updatedYearlyExpenses = replaceIndex(
      updatedYearlyExpenses,
      selectedMonth,
      undefined
    );

    const nextMonthExpenses = yearlyExpenses[selectedMonth + 1];

    if (nextMonthExpenses) {
      const updatedNextMonthExpenses = {
        ...nextMonthExpenses,
        rateOfChange: 0
      };

      updatedYearlyExpenses = replaceIndex(
        updatedYearlyExpenses,
        selectedMonth + 1,
        updatedNextMonthExpenses
      );
    }

    setYearlyExpenses(updatedYearlyExpenses);
  };

  const handleMonthlyExpenseEditorClose = () => (
    setIsMonthlyExpenseEditorOpen(false)
  );

  const handleMonthlyExpenseEditorOpen = () => (
    setIsMonthlyExpenseEditorOpen(true)
  );

  const handleMonthlyExpenseEditorSubmit = async value => {
    const currentMonthlyExpenses = yearlyExpenses[selectedMonth];

    let updatedExpense;

    try {
      if (currentMonthlyExpenses) {
        updatedExpense = await client.vehicle.putMonthlyVehicleExpenses({
          vehicleId: id,
          year: selectedYear,
          month: selectedMonth,
          expenses: value
        });
      } else {
        updatedExpense = await client.vehicle.postMonthlyVehicleExpenses({
          vehicleId: id,
          year: selectedYear,
          month: selectedMonth,
          expenses: value
        });
      }
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.submitExpenses"), {
        variant: "error"
      });

      return;
    }

    let updatedYearlyExpenses = yearlyExpenses;
    const nextMonthExpenses = yearlyExpenses[selectedMonth + 1];

    updatedYearlyExpenses = replaceIndex(
      updatedYearlyExpenses,
      selectedMonth,
      updatedExpense
    );

    if (nextMonthExpenses) {
      const updatedRateOfChange = calculateRateOfChange(
        nextMonthExpenses,
        updatedExpense
      );

      const updatedNextMonthExpenses = {
        ...nextMonthExpenses,
        rateOfChange: updatedRateOfChange
      };

      updatedYearlyExpenses = replaceIndex(
        updatedYearlyExpenses,
        selectedMonth + 1,
        updatedNextMonthExpenses
      );
    }

    setIsMonthlyExpenseEditorOpen(false);
    setYearlyExpenses(updatedYearlyExpenses);
  };

  const handleSelectedYearChange = year => setSelectedYear(year);
  const handleSelectedMonthChange = month => setSelectedMonth(month);
  const handleSelectedMonthlyExpensesChange = expenses => (
    setSelectedMonthlyExpenses(expenses)
  );

  const loadYearlyVehicleExpenses = async () => {
    setIsLoading(true);

    let previousMonthlyExpenses;
    let yearlyExpenses;

    try {
      [previousMonthlyExpenses, yearlyExpenses] = await Promise.all([
        client.vehicle.getPreviousMonthlyVehicleExpenses({
          vehicleId: id, year: selectedYear
        }),
        client.vehicle.getYearlyVehicleExpenses({
          vehicleId: id, year: selectedYear
        })
      ]);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.loadExpenses"), {
        variant: "error"
      });

      return;
    }

    if (!previousMonthlyExpenses) {
      previousMonthlyExpenses = defaultMonthlyExpenses;
    }

    setYearlyExpenses(yearlyExpenses);
    setPreviousMonthlyExpenses(previousMonthlyExpenses);
    setIsLoading(false);
  };

  useEffect(() => {
    loadYearlyVehicleExpenses();
  }, [selectedYear]);

  useEffect(() => {
    onBreadcrumbsChange([
      { label: t("webapp:common.title.vehicles"), href: "/vehicles" },
      { label: `${manufacturer} ${model}`, href: `/vehicles/${id}` },
      { label: t("webapp:vehicle.title.expenses") }
    ]);
  }, []);

  if (isLoading || areExchangeRatesLoding) {
    return (
      <div className={clsx(classes.progress, className)}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <VehicleExpenseOverview
      className={className}
      firstYear={firstYear}
      lastYear={lastYear}
      selectedYear={selectedYear}
      selectedMonth={selectedMonth}
      yearlyExpenses={yearlyExpenses}
      previousMonthlyExpenses={previousMonthlyExpenses}
      selectedMonthlyExpenses={selectedMonthlyExpenses}
      isMonthlyExpenseCreationDisabled={
        !hasPermission("resource.vehicle.expenses.create")
      }
      isMonthlyExpenseDeletionDisabled={
        !hasPermission("resource.vehicle.expenses.delete")
      }
      isMonthlyExpenseEditationDisabled={
        !hasPermission("resource.vehicle.expenses.update")
      }
      isMonthlyExpenseEditorOpen={isMonthlyExpenseEditorOpen}
      isMonthlyExpenseDeleteAlertOpen={isMonthlyExpenseDeleteAlertOpen}
      onMonthlyExpenseDeleteAlertClose={handleMonthlyExpenseDeleteAlertClose}
      onMonthlyExpenseDeleteAlertOpen={handleMonthlyExpenseDeleteAlertOpen}
      onMonthlyExpenseDeleteAlertSubmit={handleMonthlyExpenseDeleteAlertSubmit}
      onMonthlyExpenseEditorClose={handleMonthlyExpenseEditorClose}
      onMonthlyExpenseEditorOpen={handleMonthlyExpenseEditorOpen}
      onMonthlyExpenseEditorSubmit={handleMonthlyExpenseEditorSubmit}
      onSelectedYearChange={handleSelectedYearChange}
      onSelectedMonthChange={handleSelectedMonthChange}
      onSelectedMonthlyExpensesChange={handleSelectedMonthlyExpensesChange}
    />
  );
};

export default VehicleExpenseOverviewContainer;
