import React, { useState } from "react";

import {
  Chip,
  Fab,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";

import {
  Add,
  CloudDownloadOutlined,
  ControlPoint,
  FileCopyOutlined,
  GetApp,
  Search,
  DeleteOutline,
  EmojiObjectsOutlined,
  Close,
  Edit,
  Payment,
  Email,
  Settings
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

import { ShipmentType, ContactType } from "@cargotic/model-deprecated";

import config from "../../../config";

import DataTable from "../../../datatable";
import ExportDialog from "./ExportDialog";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import InvoiceDialog from "./InvoiceDialog";
import SearchTextfield from "../../common/SearchTextfield";
import SendOrderDialog from "../../../shipment/component/SendOrderDialog";

import PayDialog from "../../../shipment/component/PayDialog";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import FilterTextDropdown from "../../../../cargotic-webapp-filter/component/FilterTextDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import VehiclesFilterDropdown from "../../../../cargotic-webapp-filter/component/VehiclesFilterDropdown";
import { addDays } from "date-fns";

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)"
  }
}));

const DownloadOrderPopoverContent = ({
  id
}) => (
  <React.Fragment>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a href={`${config.api.url}/pdf/shipment/${id}?language=en`} target="_blank" download>
          <ReactCountryFlag
            code="gb"
            svg
            styleProps={{
              width: "32px",
              height: "32px"
            }}
          />
        </a>
      </Grid>
    </Grid>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a href={`${config.api.url}/pdf/shipment/${id}?language=cs`} target="_blank" download>
          <ReactCountryFlag
            code="cz"
            svg
            styleProps={{
              width: "32px",
              height: "32px"
            }}
          />
        </a>
      </Grid>
    </Grid>
  </React.Fragment>
);

const SendOrderPopoverContent = ({ id }) => (
  <React.Fragment>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a href={`${config.api.url}/pdf/shipment/${id}?language=en`} target="_blank" download>
          <ReactCountryFlag
            code="gb"
            svg
            styleProps={{
              width: "32px",
              height: "32px"
            }}
          />
        </a>
      </Grid>
    </Grid>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a href={`${config.api.url}/pdf/shipment/${id}?language=cs`} target="_blank" download>
          <ReactCountryFlag
            code="cz"
            svg
            styleProps={{
              width: "32px",
              height: "32px"
            }}
          />
        </a>
      </Grid>
    </Grid>
  </React.Fragment>
);

const DownloadInvoicePopoverContent = ({
  id
}) => (
  <React.Fragment>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a href={`${config.api.url}/pdf/shipmentInvoice/${id}?language=en`} target="_blank" download>
          <ReactCountryFlag
            code="gb"
            svg
            styleProps={{
              width: "32px",
              height: "32px"
            }}
          />
        </a>
      </Grid>
    </Grid>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a href={`${config.api.url}/pdf/shipmentInvoice/${id}?language=cs`} target="_blank" download>
          <ReactCountryFlag
            code="cz"
            svg
            styleProps={{
              width: "32px",
              height: "32px"
            }}
          />
        </a>
      </Grid>
    </Grid>
  </React.Fragment>
);

const Shipments = ({
  data,
  dataCount,
  shipments,
  setShipments,
  selectedColumns,
  search,
  loading,
  ordering,
  direction,
  page,
  rowsPerPage,
  checkedAll,
  isShipmentsExportDialogOpen,
  isShipmentsExportProcessing,
  isShipmentsExportDone,
  shipmentsExportLink,
  closeShipmentsExportDialog,
  exportShipments,
  canCreateShipment,
  canUpdateShipment,
  canDeleteShipment,
  canCreateInvoice,
  canCreateOrderPdf,
  canReadCompanyShipment,
  canReadInvoice,
  additionalFilter: { type: additionalFilterType, lastWaypointDateFrom: additionalFilterLastDateFrom },
  handlePayShipment,
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleShipmentsExportRequest,
  handleSelectAll,
  handleSelect,
  handleSelectCarried,
  handleSelectForwarded,
  handleSelectLastMonth,
  handleDeleteRequest,
  handleGenerateShipmentInvoice,
  filter,
  availableBankAccounts,
  handleSelectShipmentType,
  handleDeselect,
  handleSelectLoadingsDateRange,
  handleSelectUnloadingsDateRange,
  handleSelectCreatedAtDateRange,
  handleSelectCreators,
  handleSelectDrivers,
  loadAvailableUsers,
  handleSelectCustomer,
  handleSelectCarrier,
  handleSelectCustomerPrice,
  handleSelectCarrierPrice,
  handleSelectCommission,
  loadAvailableContacts,
  handleFilterSettingsOpen,
  defaultFilters,
  handleSelectCargoTemplate,
  handleSelectIsDraft,
  handleSelectVehicles,
  loadAvailableVehicles,
  handleSelectIssuedPaymentState,
  handleSelectReceivedPaymentState,
  handleSendOrder,
  handleChangeSelectedColumns
}) => {
  const { history } = useRouter();
  const classes = useStyles();
  const { t } = useTranslation();

  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] = useState(false);
  const [
    showMissingIssuedInvoiceNumberModal,
    setShowMissingIssuedInvoiceNumberModal
  ] = useState(false);
  const [invoiceDialogShipmentId, setInvoiceDialogShipmentId] = useState(0);
  const [invoiceDialogIssuedInvoiceNumber, setInvoiceDialogIssuedInvoiceNumber] = useState();

  const [payShipmentDialogOpen, setPayShipmentDialogOpen] = useState(false);
  const [payShipmentDialogData, setPayShipmentDialogData] = useState({});

  const [sendOrderDialogOpen, setSendOrderDialogOpen] = useState(false);
  const [sendOrderDialogData, setSendOrderDialogData] = useState({});

  const [loadingPayAction, setLoadingPayAction] = useState(false);
  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const handleGenerateInvoiceAndRedirect = async (id) => {
    const w = window.open("", "_blank");
    await handleGenerateShipmentInvoice(id);
    w.location.href = `${config.api.url}/pdf/shipmentInvoice/${id}?language=cs`;
  };

  const handleGenerateInvoiceClick = (id) => {
    const [{ issuedInvoiceNumber, hasInvoice }] = shipments.filter(({ id: shipmentId, hasInvoice }) => shipmentId === id);

    if (hasInvoice) {
      setInvoiceDialogShipmentId(id);
      setInvoiceDialogIssuedInvoiceNumber(issuedInvoiceNumber);
      setShowGenerateInvoiceModal(true);
    } else {
      if (!issuedInvoiceNumber) {
        setInvoiceDialogShipmentId(id);
        setShowMissingIssuedInvoiceNumberModal(true);
      }
      else {
        handleGenerateInvoiceAndRedirect(id);
      }
    }
  };

  const expandFilters = (values, fullValues) => values.map(item => fullValues.find(i => i.value === item));

  const cargoSource = [
    { title: t("core:cargo.item.template.EUR_PALLET"), value: "EUR_PALLET" },
    { title: t("core:cargo.item.template.US_PALLET"), value: "US_PALLET" },
    { title: t("core:cargo.item.template.HALF_EUR_PALLET"), value: "HALF_EUR_PALLET" },
    { title: t("core:cargo.item.template.LDM"), value: "LDM" },
    { title: t("core:cargo.item.template.LKW"), value: "LKW" },
    { title: t("core:cargo.item.template.TANDEM"), value: "TANDEM" },
    { title: t("core:cargo.item.template.MEGA"), value: "MEGA" },
    { title: t("core:cargo.item.template.SOLO"), value: "SOLO" },
    { title: t("core:cargo.item.template.VAN"), value: "VAN" },
    { title: t("core:cargo.item.template.undefined"), value: undefined },
    { title: t("core:cargo.item.template.FRIGO_SEMI_TRAILER"), value: "FRIGO_SEMI_TRAILER" },
    { title: t("core:cargo.item.template.BOX_SEMI_TRAILER"), value: "BOX_SEMI_TRAILER" }
  ];

  const handleSendOrderClick = (id) => {
    const [{
      carrierContact,
      carrierEmployee,
      orderSerialNumber
    }] = shipments.filter(({ id: shipmentId }) => id === shipmentId);

    setSendOrderDialogData({
      id,
      orderSerialNumber,
      email: carrierEmployee && carrierEmployee.email ? carrierEmployee.email : carrierContact.email,
      message: "",
      isSendCopyToMeChecked: false
    });

    setSendOrderDialogOpen(true);
  };

  const handlePayShipmentClick = (id) => {
    const [{
      issuedInvoicePaidAt,
      issuedInvoiceNumber,
      issuedInvoiceDueDate,
      receivedInvoicePaidAt,
      receivedInvoiceDueDate,
      receivedInvoiceNumber,
      bankAccountId,
      taxableSupplyDate,
      invoiceNote,
      issueDate,
      journey: { waypoints },
      customerPaymentDueDays
    }] = shipments.filter(({ id: shipmentId }) => id === shipmentId);

    const lastUnloading = waypoints[waypoints.length - 1];
    const lastUnloadingDateTime = lastUnloading.arriveAtTo ?? lastUnloading.arriveAtFrom;

    setPayShipmentDialogData({
      id,
      issuedInvoicePaidAt,
      issuedInvoiceNumber,
      issuedInvoiceDueDate: issuedInvoiceDueDate ?? addDays(new Date(), customerPaymentDueDays),
      receivedInvoicePaidAt,
      receivedInvoiceDueDate,
      receivedInvoiceNumber,
      bankAccountId,
      taxableSupplyDate: taxableSupplyDate ?? lastUnloadingDateTime,
      invoiceNote,
      issueDate: issueDate ?? new Date()
    });
    setPayShipmentDialogOpen(true);
  }

  const handleSendOrderSubmit = async ({
    email,
    message,
    language,
    isSendCopyToMeChecked
  }) => {
    setSendOrderDialogOpen(false);
    await handleSendOrder(
      sendOrderDialogData.id,
      {
        email,
        message,
        language,
        isSendCopyToMeChecked
      }
    );
  };

  const handlePayShipmentSubmit = async ({
    issuedInvoiceNumber,
    issuedInvoiceDueDate,
    issuedInvoicePaidAt,
    receivedInvoiceDueDate,
    receivedInvoiceNumber,
    receivedInvoicePaidAt,
    bankAccountId,
    taxableSupplyDate,
    invoiceNote,
    issueDate
  }) => {

    setLoadingPayAction(true);
    setPayShipmentDialogOpen(false);
    await handlePayShipment(
      payShipmentDialogData.id,
      {
        issuedInvoiceNumber,
        issuedInvoiceDueDate,
        issuedInvoicePaidAt,
        receivedInvoiceDueDate,
        receivedInvoiceNumber,
        receivedInvoicePaidAt,
        bankAccountId,
        taxableSupplyDate,
        invoiceNote,
        issueDate
      }
    );
    const updatedShipments = shipments.map((shipment) =>
      shipment.id === payShipmentDialogData.id
        ? {
          ...shipment,
          issuedInvoiceNumber,
          issuedInvoiceDueDate,
          issuedInvoicePaidAt,
          receivedInvoiceDueDate,
          receivedInvoiceNumber,
          receivedInvoicePaidAt,
          bankAccountId,
          taxableSupplyDate,
          invoiceNote,
          issueDate
        }
        : shipment);
    setShipments(updatedShipments);

    setPayShipmentDialogData({
      id: payShipmentDialogData.id,
      issuedInvoicePaidAt,
      issuedInvoiceNumber,
      issuedInvoiceDueDate,
      receivedInvoicePaidAt,
      receivedInvoiceDueDate,
      receivedInvoiceNumber,
      bankAccountId,
      taxableSupplyDate,
      invoiceNote,
      issueDate
    });
    setLoadingPayAction(false);
  }

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const shipmentsHeader = [
    {
      name: "indexNumber",
      label: "shipments.shipmentNumber",
      isOrderable: true,
      width: "10%"
    },
    {
      name: "pickupAtStart",
      label: "shipments.date",
      isOrderable: true,
      width: "20%"
    },
    {
      name: "route",
      label: "shipments.route",
      isOrderable: false
    },
    {
      name: "createdBy",
      label: "shipments.creator",
      isOrderable: true
    },
    {
      name: "commissionNativePrice",
      label: "shipments.commission",
      isOrderable: true
    },
    {
      name: "paidAt",
      label: "shipments.invoicing",
      width: "15%",
      isOrderable: false
    },
    {
      name: "type",
      label: "",
      isOrderable: false
    }, {
      name: "customerPrice",
      label: "shipments.customerPrice",
      isOrderable: true
    },
    {
      name: "carrierPrice",
      label: "shipments.carrierPrice",
      isOrderable: true
    },
    {
      name: "vehicle",
      label: "shipments.vehicle",
      isOrderable: true
    },
    {
      name: "state",
      label: "board.state.title",
      isOrderable: true
    }, {
      name: "driver",
      label: "shipments.driver",
      isOrderable: true
    }, {
      name: "customerContact",
      label: "contacts.customer",
      isOrderable: true
    },
    {
      name: "carrierContact",
      label: "contacts.carrier",
      isOrderable: true
    }
  ];

  const invoiceDisable = [];
  if (canReadInvoice) {
    for (let index = 0; index < shipments.length; index++) {
      if (!shipments[index].hasInvoice) {
        invoiceDisable.push(shipments[index].id);
      }
    }
  } else {
    invoiceDisable.push(-1);
  }

  const payDisable = [];
  if (canCreateInvoice) {
    for (let index = 0; index < shipments.length; index++) {
      if (shipments[index].isDraft) {
        payDisable.push(shipments[index].id);
      }
    }
  } else {
    payDisable.push(-1);
  }

  const shipmentPdfDisable = [];
  if (canCreateOrderPdf) {
    shipments.forEach(({ type, isDraft }, index) => {
      if (type === ShipmentType.CARRIED || isDraft) {
        shipmentPdfDisable.push(shipments[index].id);
      }
    });
  } else {
    shipmentPdfDisable.push(-1);
  }

  const createInvoiceDisable = [];
  if (canCreateInvoice) {
    shipments.forEach(({ isDraft, bankAccountId }, index) => {
      if (isDraft || !bankAccountId) {
        createInvoiceDisable.push(shipments[index].id);
      }
    });
  } else {
    createInvoiceDisable.push(-1);
  }

  const tableActions = [
    {
      column: [
        {
          handleClick: (id) => history.push(`/shipment/${id}/copy`),
          icon: <FileCopyOutlined />,
          title: "copy",
          disableFor: !canCreateShipment ? [-1] : undefined
        }
      ]
    },
    {
      column: [
        {
          icon: <GetApp />,
          title: "downloadOrder",
          usePopover: true,
          PopoverContent: DownloadOrderPopoverContent,
          disableFor: shipmentPdfDisable
        }, {
          icon: <Email />,
          title: "sendOrder",
          handleClick: handleSendOrderClick,
          disableFor: shipmentPdfDisable
        }
      ]
    },
    {
      column: [
        {
          handleClick: handleGenerateInvoiceClick,
          icon: <ControlPoint />,
          title: "generateInvoice",
          disableFor: createInvoiceDisable
        },
        {
          icon: <GetApp />,
          title: "downloadInvoice",
          usePopover: true,
          PopoverContent: DownloadInvoicePopoverContent,
          disableFor: invoiceDisable
        }
      ]
    },
    {
      column: [
        {
          handleClick: handlePayShipmentClick,
          icon: <Payment />,
          title: "shipments.invoicing",
          disableFor: payDisable
        }
      ]
    },
    {
      column: [
        {
          handleClick: (id) => history.push(`/shipment/${id}`),
          icon: <Edit />,
          title: "shipments.edit",
          disableFor: !canUpdateShipment ? [-1] : undefined
        }
      ]
    }
  ];

  const shipmentTypeSource = [
    { title: t("statistics.forwarded"), value: ShipmentType.FORWARDED },
    { title: t("statistics.carried"), value: ShipmentType.CARRIED }
  ];

  const paymentSource = [
    { title: t("shipments.non-issued"), value: "NON_ISSUED" },
    { title: t("shipments.issued"), value: "ISSUED" },
    { title: t("shipments.paid"), value: "PAID" },
    { title: t("shipments.overdue-title"), value: "OVERDUE" }
  ];

  const draftSource = [{ title: t("shipments.draft"), value: "DRAFT" }, { title: t("shipments.completed"), value: "COMPLETED" }];
  const setDraft = (value) => {
    if (value.includes("DRAFT") && value.includes("COMPLETED")) {
      handleSelectIsDraft(null);
    } else if (value.includes("COMPLETED")) {
      handleSelectIsDraft(false);
    } else if (value.includes("DRAFT")) {
      handleSelectIsDraft(true);
    } else {
      handleSelectIsDraft(undefined);
    }
  };

  const isAnyFilterActive = () => {
    const {
      types,
      drivers,
      carrierPrice,
      customerPrice,
      commission,
      carriers,
      customers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      issuedPaymentState,
      receivedPaymentState,
      vehicles,
      isDraft
    } = filter;

    return [
      types,
      drivers,
      carrierPrice,
      customerPrice,
      commission,
      carriers,
      customers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      issuedPaymentState,
      receivedPaymentState,
      vehicles,
      isDraft
    ].some(item => item !== undefined);
  };

  return (
    <Page
      title={t("menu.shipments")}
      actions={(
        <>
          <Tooltip title={t("newShipment")}>
            <span>
              <Fab
                color="primary"
                disabled={loading || !canCreateShipment}
                onClick={() => history.push("/shipment")}
              >
                <Add />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title={t("shipments.export.action")}>
            <span>
              <Fab
                color="primary"
                disabled={loading || shipments.length === 0}
                onClick={handleShipmentsExportRequest}
              >
                <CloudDownloadOutlined />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title={t("shipments.removeShipment")}>
            <span>
              <Fab
                className={classes.danger}
                disabled={loading || selected === 0 || !canDeleteShipment}
                onClick={handleDeleteRequest}
                data-cy="remove-shipment"
              >
                <DeleteOutline />
              </Fab>
            </span>
          </Tooltip>
        </>
      )}
      tertiaryActions={(
        <>
          <Tooltip title={(
            <>
              <Typography color="inherit" style={{ fontWeight: 800 }}>{t("didYouKnowThat")}</Typography>
              <Typography color="inherit">{t("proTableTip")}</Typography>
            </>
          )}
          >
            <IconButton>
              <EmojiObjectsOutlined />
            </IconButton>
          </Tooltip>
        </>
      )}
    >
      <FilterContainer
        searchField={<SearchTextfield handleSearch={handleSearch} value={search} fullWidth placeholder={t("shipments.searchTooltip")} />}
        showClearButton={isAnyFilterActive}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        defaultFilters={defaultFilters}
        loading={loading}
        filters={[
          <FilterStaticDropdown
            id="types"
            key="types"
            placeholderTitle={t("statistics.shipmentType")}
            value={filter.types ?? []}
            onChange={(arr) => handleSelectShipmentType(arr.length === 0 ? undefined : arr)}
            selectAll={() => handleSelectShipmentType(shipmentTypeSource.map(item => item.value))}
            onClear={() => handleSelectShipmentType(undefined)}
            source={shipmentTypeSource}
          />,
          <FilterDateRangeDropdown
            id="loadingDate"
            key="loadingDate"
            onChange={(value) => handleSelectLoadingsDateRange(value)}
            value={filter.loadingDate ? [filter.loadingDate.from, filter.loadingDate.to] : []}
            onClear={() => handleSelectLoadingsDateRange(undefined)}
            placeholderTitle={t("shipments.loadingsDateRange")}
          />,
          <FilterDateRangeDropdown
            id="unloadingDate"
            key="unloadingDate"
            onChange={(value) => handleSelectUnloadingsDateRange(value)}
            value={filter.unloadingDate ? [filter.unloadingDate.from, filter.unloadingDate.to] : []}
            onClear={() => handleSelectUnloadingsDateRange(undefined)}
            placeholderTitle={t("shipments.unloadingsDateRange")}
          />,
          <UserFilterDropdown
            id="creators"
            key="creators"
            onChange={(value) => handleSelectCreators(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectCreators(all)}
            value={filter.creators ?? []}
            placeholderTitle={t("shipments.creator")}
            search={(text) => loadAvailableUsers(text)}
            onClear={() => handleSelectCreators(undefined)}
            getTitle={(item) => item.name}
            disabled={!canReadCompanyShipment}
          />,
          <UserFilterDropdown
            id="drivers"
            key="drivers"
            onChange={(value) => handleSelectDrivers(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectDrivers(all)}
            value={filter.drivers ?? []}
            placeholderTitle={t("shipments.driver")}
            search={(text) => loadAvailableUsers(text, "DRIVER")}
            onClear={() => handleSelectDrivers(undefined)}
            getTitle={(item) => item.name}
          />,
          <FilterDateRangeDropdown
            id="createdAt"
            key="createdAt"
            onChange={(value) => handleSelectCreatedAtDateRange(value)}
            value={filter.createdAt ? [filter.createdAt.from, filter.createdAt.to] : []}
            onClear={() => handleSelectCreatedAtDateRange(undefined)}
            placeholderTitle={t("shipments.creationDate")}
          />,
          <ContactFilterDropdown
            id="customers"
            key="customers"
            onChange={(value) => handleSelectCustomer(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectCustomer(all)}
            value={filter.customers ?? []}
            search={(text) => loadAvailableContacts(ContactType.CUSTOMER, text)}
            placeholderTitle={t("contacts.customer")}
            onClear={() => handleSelectCustomer(undefined)}
            getTitle={(item) => item.name}
          />,
          <ContactFilterDropdown
            id="carriers"
            key="carriers"
            onChange={(value) => handleSelectCarrier(value.length === 0 ? undefined : value)}
            value={filter.carriers ?? []}
            selectAll={(all) => handleSelectCarrier(all)}
            placeholderTitle={t("contacts.carrier")}
            onClear={() => handleSelectCarrier(undefined)}
            search={(text) => loadAvailableContacts(ContactType.CARRIER, text)}
            getTitle={(item) => item.name}
          />,
          <FilterNumberRange
            id="customerPrice"
            key="customerPrice"
            onChange={(value) => handleSelectCustomerPrice(value)}
            value={filter.customerPrice}
            placeholderTitle={t("shipments.customerPrice")}
            onClear={() => handleSelectCustomerPrice(undefined)}
          />,
          <FilterNumberRange
            id="carrierPrice"
            key="carrierPrice"
            onChange={(value) => handleSelectCarrierPrice(value)}
            value={filter.carrierPrice}
            placeholderTitle={t("shipments.carrierPrice")}
            onClear={() => handleSelectCarrierPrice(undefined)}
          />,
          <FilterNumberRange
            id="commission"
            key="commission"
            onChange={(value) => handleSelectCommission(value)}
            value={filter.commission}
            placeholderTitle={t("shipments.commission")}
            onClear={() => handleSelectCommission(undefined)}
          />,
          <FilterStaticDropdown
            id="issuedPaymentState"
            key="issuedPaymentState"
            placeholderTitle={t("shipments.issuedPaymentState")}
            value={filter.issuedPaymentState ?? []}
            onChange={(arr) => handleSelectIssuedPaymentState(arr.length === 0 ? undefined : arr)}
            selectAll={() => handleSelectIssuedPaymentState(paymentSource.map(item => item.value))}
            onClear={() => handleSelectIssuedPaymentState(undefined)}
            source={paymentSource}
          />,
          <FilterStaticDropdown
            id="receivedPaymentState"
            key="receivedPaymentState"
            placeholderTitle={t("shipments.receivedPaymentState")}
            value={filter.receivedPaymentState ?? []}
            onChange={(arr) => handleSelectReceivedPaymentState(arr.length === 0 ? undefined : arr)}
            selectAll={() => handleSelectReceivedPaymentState(paymentSource.map(item => item.value))}
            onClear={() => handleSelectReceivedPaymentState(undefined)}
            source={paymentSource}
          />,
          <FilterStaticDropdown
            id="cargo"
            key="cargo"
            placeholderTitle={t("shipments.cargo")}
            value={filter.cargo ?? []}
            onChange={(arr) => handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)}
            selectAll={() => handleSelectCargoTemplate(cargoSource.map(item => item.value))}
            onClear={() => handleSelectCargoTemplate(undefined)}
            source={cargoSource}
          />,
          <FilterStaticDropdown
            id="isDraft"
            key="isDraft"
            placeholderTitle={t("shipments.draft")}
            value={filter.isDraft === undefined ? [] : (filter.isDraft === null ? ["DRAFT", "COMPLETED"] : (filter.isDraft === true ? ["DRAFT"] : ["COMPLETED"]))}
            onChange={(value) => setDraft(value)}
            selectAll={() => setDraft(draftSource.map(item => item.value))}
            onClear={() => handleSelectIsDraft(undefined)}
            source={draftSource}
          />,
          <VehiclesFilterDropdown
            id="vehicles"
            key="vehicles"
            onChange={(value) => handleSelectVehicles(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectVehicles(all)}
            value={filter.vehicles ?? []}
            placeholderTitle={t("shipments.vehicles")}
            search={(text) => loadAvailableVehicles(text)}
            onClear={() => handleSelectVehicles(undefined)}
          />
        ]}
        onClear={handleDeselect}
        onOpenSelectColumnsDialog={() => setIsOpenSelectColumnsDialog(true)}
      />
      <DataTable
        headers={shipmentsHeader}
        actions={tableActions}
        data={data}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        additionalColumns={["type"]}
        loading={loading || loadingPayAction}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        enableDetails
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangeSelectedColumns={handleChangeSelectedColumns}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
      />
      <InvoiceDialog
        id={invoiceDialogShipmentId}
        title={t("orders.invoice.title")}
        content={t("orders.invoice.message")}
        generateButtonLabel={t("orders.invoice.generate")}
        closeButtonLabel={t("orders.invoice.close")}
        isOpen={showGenerateInvoiceModal}
        handleGenerateShipmentInvoice={() => {
          if (invoiceDialogIssuedInvoiceNumber) {
            handleGenerateInvoiceAndRedirect(invoiceDialogShipmentId);
          }
          else {
            setShowMissingIssuedInvoiceNumberModal(true);
          }

          setShowGenerateInvoiceModal(false);
        }}
        handleClose={() => setShowGenerateInvoiceModal(false)}
      />
      <InvoiceDialog
        id={"missingIssuedInvoiceNumber" + invoiceDialogShipmentId}
        // title={t("orders.invoice.title")}
        // content={t("orders.invoice.message")}
        title={t("orders.invoice.missingIssuedInvoiceNumberTitle")}
        content={t("orders.invoice.missingIssuedInvoiceNumberMessage")}
        generateButtonLabel={t("orders.invoice.generate")}
        closeButtonLabel={t("orders.invoice.close")}
        isOpen={showMissingIssuedInvoiceNumberModal}
        handleGenerateShipmentInvoice={() => {
          handleGenerateInvoiceAndRedirect(invoiceDialogShipmentId);
          setShowMissingIssuedInvoiceNumberModal(false);
        }}
        handleClose={() => setShowMissingIssuedInvoiceNumberModal(false)}
      />
      <ExportDialog
        infoText={t("orders.export.info.text")}
        title={t("orders.export.title")}
        closeButtonLabel={t("orders.export.close")}
        downloadCSV={t("orders.export.downloadCSV")}
        startButtonLabel={t("orders.export.start")}
        processingText={t("orders.export.processing.text")}
        doneText={t("orders.export.done.text")}
        linkLabel={t("orders.export.done.link")}
        isDone={isShipmentsExportDone}
        isOpen={isShipmentsExportDialogOpen}
        isProcessing={isShipmentsExportProcessing}
        link={shipmentsExportLink}
        close={closeShipmentsExportDialog}
        exportCsv={exportShipments}
      />
      <PayDialog
        issuedInvoiceDueDate={payShipmentDialogData.issuedInvoiceDueDate}
        issuedInvoicePaidAt={payShipmentDialogData.issuedInvoicePaidAt}
        issuedInvoiceNumber={payShipmentDialogData.issuedInvoiceNumber}

        receivedInvoiceDueDate={payShipmentDialogData.receivedInvoiceDueDate}
        receivedInvoiceNumber={payShipmentDialogData.receivedInvoiceNumber}
        receivedInvoicePaidAt={payShipmentDialogData.receivedInvoicePaidAt}

        bankAccountValue={payShipmentDialogData.bankAccountId}
        bankAccounts={availableBankAccounts}

        invoiceNote={payShipmentDialogData.invoiceNote}
        taxableSupplyDate={payShipmentDialogData.taxableSupplyDate}
        issueDate={payShipmentDialogData.issueDate}

        isOpen={payShipmentDialogOpen}
        handleSubmit={handlePayShipmentSubmit}
        handleClose={() => setPayShipmentDialogOpen(false)}

        disableReceivedInvoice={shipments.find(({ id }) => id === payShipmentDialogData.id) ? shipments.find(({ id }) => id === payShipmentDialogData.id).type === ShipmentType.CARRIED : false}
      />
      <SendOrderDialog
        email={sendOrderDialogData.email}
        message={t("webapp:shipment.send-order.label.default-text", { number: sendOrderDialogData.orderSerialNumber })}
        language="cs"

        shipmentId={sendOrderDialogData.id}
        isOpen={sendOrderDialogOpen}
        handleSubmit={handleSendOrderSubmit}
        handleClose={() => setSendOrderDialogOpen(false)}
      />
    </Page>
  );
};

export default Shipments;
