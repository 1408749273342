import firebase from "firebase/compat/app";

import React from "react";
import { render } from "react-dom";
import TagManager from 'react-gtm-module'

import App from "./component/core/App";
import { loadScript } from "./utility/dom";
import config from "./config";

if (location.host === "app.master.cargotic.com") {
  window.location.href = "https://app.cargotic.com";
}
const tagManagerArgs = {
  gtmId: config.tagManager.trackingId
}

const main = async () => {
  await loadScript(config.googleAnalytics.url);

  firebase.initializeApp(config.firebase);
  TagManager.initialize(tagManagerArgs);

  render(<App />, document.querySelector("#app"));
};

main();
