import React, { useState } from "react"
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Checkbox,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Popover,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core"

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert
} from "@material-ui/icons";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
    borderRadius: 15,
  },
  cardCell: {
    margin: spacing(2),
  },
  opacityUp: {
    opacity: 1,
    transition: "opacity .25s ease-in-out"
  },
  opacityDown: {
    opacity: 0,
    transition: "opacity .25s ease-in-out"
  },
}));

const DrawCheckbox = ({
  tableMode,
  selected,
  index,
  selectOnlyOne,
  onSelect,
  disabled
}) => {
  if (!tableMode) {
    return null;
  }
  return (
    <TableCell padding="checkbox">
      <Checkbox
        color="primary"
        checked={selected}
        disabled={disabled}
        onChange={({ target: { checked: selected } }) => onSelect(index, selected, selectOnlyOne)}
      />
    </TableCell>
  );
};

const DrawAction = ({
  id,
  handleClick,
  icon,
  title,
  usePopover,
  PopoverContent,
  disabled,
  showActions
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorElTable, setAnchorElTable] = useState(null);
  const handleClosePopover = () => setAnchorElTable(null);
  const handleClickPopover = event => setAnchorElTable(event.currentTarget);

  const IconButtonProps = usePopover
    ? {
      onClick: handleClickPopover,
      disabled
    } : {
      onClick: () => handleClick(id),
      disabled
    };

  return (
    <Grid item className={showActions ? classes.opacityUp : classes.opacityDown}>
      <Tooltip title={t(title)}>
        <span>
          <IconButton {...IconButtonProps}>
            {icon}
          </IconButton>
        </span>
      </Tooltip>
      {usePopover ? (
        <Popover
          open={Boolean(anchorElTable)}
          anchorEl={anchorElTable}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <PopoverContent id={id} />
        </Popover>
      ) : null}
    </Grid>
  );
};

const DrawActions = ({
  actions,
  id,
  enableDetails,
  detailed,
  setDetailed,
  enableMenu,
  menuItems,
  showActions,
}) => {
  const { t } = useTranslation();

  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const handleCloseMenu = () => setAnchorElMenu(null);
  const handleClickMenu = event => setAnchorElMenu(event.currentTarget);

  return (
    <Grid
      key={`${id}-grid`}
      container
      direction="row"
      alignItems="center"
      wrap="nowrap"
      justify="center">
      {actions.map(({
        column
      }, index) => (
          <Grid
            key={`${index}-grid-row`}
            container
            item
            direction="column">
            {column.map(({
              handleClick, icon, title, usePopover, PopoverContent, disableFor
            }, index) => (
                <DrawAction
                  key={`${index}-draw-action`}
                  id={id}
                  handleClick={handleClick}
                  icon={icon}
                  title={title}
                  usePopover={usePopover}
                  PopoverContent={PopoverContent}
                  disabled=
                  {(disableFor != undefined && disableFor.includes(-1))
                    || (disableFor != undefined && disableFor.includes(id))}
                  showActions={showActions}
                />
              ))}
          </Grid>
        ))}
      {enableDetails ? (
        <Grid item>
          <Typography variant="body2">
            <IconButton 
              onClick={() => setDetailed(id === detailed ? -1 : id)}
              data-cy="show-details"
            >
              {detailed !== id ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
          </Typography>
        </Grid>
      ) : null}
      {enableMenu ? (
        <Grid item>
          <Typography variant="body2">
            <IconButton onClick={handleClickMenu}>
              <MoreVert />
            </IconButton>
            <Menu
              id="table-row-menu"
              anchorEl={anchorElMenu}
              keepMounted
              open={Boolean(anchorElMenu)}
              onClose={handleCloseMenu}
            >
              {menuItems.map(({
                handleClick, title, disableFor
              }, index) => (
                  <MenuItem
                    key={`${index}-menu-item`}
                    onClick={() => handleClick(id)}
                    disabled=
                    {(disableFor != undefined && disableFor.includes(-1))
                      || (disableFor != undefined && disableFor.includes(id))}
                  >
                    {t(title)}
                  </MenuItem>
                ))}
            </Menu>
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

const DataTableRow = ({
  disabled,
  tableMode,
  index,
  id,
  selected,
  rowActionsDisable,
  details,
  enableDetails,
  detailed,
  setDetailed,
  enableMenu,
  menuItems,
  cells,
  actions,
  selectOnlyOne,
  onSelect
}) => {
  const classes = useStyles();

  let actionsDefault = false
  if (!tableMode) {
    actionsDefault = true;
  }
  const [showActions, setShowActions] = useState(actionsDefault);

  const TrArrea = tableMode ? TableRow : Card;
  const TrArreaProps = tableMode
    ? {
      onFocus: () => setShowActions(true),
      onMouseOver: () => setShowActions(true),
      onMouseLeave: () => setShowActions(false)
    } : {
      className: classes.card,
      style: { backgroundColor: selected ? '#45CFBB' : 'white', }
    };
  const TrContent = tableMode ? React.Fragment : CardContent;
  const TrContentProps = tableMode
    ? {} : {
      onClick: () => onSelect(index, !selected, selectOnlyOne),
    };
  const TrCell = tableMode ? TableCell : "div"
  const TrCellProps = tableMode
    ? {} : {
      className: classes.cardCell
    }
  const TrActions = tableMode ? TableCell : CardActions;

  return (
    <React.Fragment>
      <TrArrea {...TrArreaProps}>
        <TrContent {...TrContentProps}>
          <DrawCheckbox
            tableMode={tableMode}
            selected={selected}
            row
            index={index}
            disabled={disabled}
            selectOnlyOne={selectOnlyOne}
            onSelect={onSelect}
          />
          {cells.map(({
            render
          }, index) => (
              <TrCell key={index} {...TrCellProps}>
                {render}
              </TrCell>
            ))}
        </TrContent>
        {actions != undefined ? (
          <TrActions>
            <DrawActions
              actions={actions}
              rowActionsDisable={rowActionsDisable}
              id={id}
              enableDetails={enableDetails}
              detailed={detailed}
              setDetailed={setDetailed}
              enableMenu={enableMenu}
              menuItems={menuItems}
              showActions={showActions}
            />
          </TrActions>
        ) : null}
        {detailed === id && !tableMode
          ? (
            <Collapse in={detailed === id && !tableMode}>
              {details || null}
            </Collapse>
          ) : null
        }
      </TrArrea>
      <TableRow>
        <TableCell colSpan={12} padding="none">
          <Collapse in={detailed === id && tableMode}>
            {detailed === id && tableMode
              && (
                details
              )
            }
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default DataTableRow