import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Fab,
  List,
  ListItem,
  ListItemText,
  Popover,
  ThemeProvider,
  Tooltip,
  createMuiTheme,
  useTheme
} from "@material-ui/core";

import { deepOrange } from "@material-ui/core/colors";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from "@material-ui/icons";

import { CargoItemListItem } from "../../../cargotic-component";
import PaneActions from "../PaneActions";

const CargoEditorActions = ({
  unloadableCargoItems,
  isCargoItemLoadingDisabled,
  isCargoItemUnloadingDisabled,
  onCargoItemLoad,
  onCargoItemUnload
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const unloadingPopoverAnchorElementRef = useRef();
  const [isUnloadingPopoverOpen, setIsUnloadingPopoverOpen] = useState(false);

  const handleLoadButtonClick = () => onCargoItemLoad();

  const handleUnloadButtonClick = () => setIsUnloadingPopoverOpen(true);

  const handleUnloadingPopoverClose = () => setIsUnloadingPopoverOpen(false);

  const handleCargoItemUnload = item => {
    handleUnloadingPopoverClose();
    onCargoItemUnload(item);
  };

  return (
    <PaneActions>
      <ThemeProvider
        theme={createMuiTheme({
          ...theme,
          palette: {
            ...theme.palette,
            secondary: deepOrange
          }
        })}
      >
        <Tooltip
          title={
            isCargoItemUnloadingDisabled
              ? t("webapp:cargo.editor.unloadingDisabled")
              : t("webapp:cargo.editor.button.unload")
          }
        >
          <span>
            <Fab
              ref={unloadingPopoverAnchorElementRef}
              color="secondary"
              size="large"
              disabled={isCargoItemUnloadingDisabled}
              onClick={handleUnloadButtonClick}
            >
              <ArrowDownwardIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip
          title={
            isCargoItemLoadingDisabled
              ? t("webapp:cargo.editor.button.disabled")
              : t("webapp:cargo.editor.button.load")
          }
        >
          <span>
            <Fab
              color="primary"
              size="large"
              disabled={isCargoItemLoadingDisabled}
              onClick={handleLoadButtonClick}
              data-cy="load-cargo-item"
            >
              <ArrowUpwardIcon />
            </Fab>
          </span>
        </Tooltip>
        <Popover
          open={isUnloadingPopoverOpen}
          anchorEl={unloadingPopoverAnchorElementRef.current}
          onClose={handleUnloadingPopoverClose}
        >
          <List>
            {unloadableCargoItems.map(item => {
              const { id } = item;

              return (
                <CargoItemListItem
                  key={id}
                  button
                  item={item}
                  onClick={() => handleCargoItemUnload(item)}
                />
              );
            })}
            <ListItem button onClick={() => handleCargoItemUnload(undefined)}>
              <ListItemText
                inset={unloadableCargoItems.length}
                primary={t("webapp:cargo.editor.other")}
              />
            </ListItem>
          </List>
        </Popover>
      </ThemeProvider>
    </PaneActions>
  );
};

export default CargoEditorActions;
