import React from "react";
import { useTranslation } from "react-i18next";

import { Button, makeStyles } from "@material-ui/core";
import { Share as ShareIcon } from "@material-ui/icons";

import {
  UserAvatar,
  UserAvatarGroup
} from "../../../cargotic-webapp-component";

import ContactPublicizeConfirmationAlert
  from "../ContactPublicizeConfirmationAlert";

import ContactShareEditor from "../ContactShareEditor";
import ContactSharingPublicizeButton from "./ContactSharingPublicizeButton";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",

    "& > :not(:first-child)": {
      marginLeft: spacing(2)
    }
  },
  avatar: {
    width: 24,
    height: 24
  }
}));

const ContactSharing = ({
  shares,
  splitting,
  contactCreatorId,
  isContactPrivate,
  isPublicizeButtonDisabled,
  isPublicizeConfirmationAlertOpen,
  isShareEditorOpen,
  onPublicizeButtonClick,
  onPublicizeConfirmationAlertClose,
  onPublicizeConfirmationAlertSubmit,
  onShareEditorClose,
  onShareEditorOpen,
  onShareEditorSubmit
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleShareButtonClick = () => {
    if (onShareEditorOpen) {
      onShareEditorOpen();
    }
  };

  return (
    <>
      <div className={classes.root}>
        {
          isContactPrivate
            ? (
              <>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={(
                      <ShareIcon />
                    )}
                    onClick={handleShareButtonClick}
                  >
                    {t("webapp:common.title.share")}
                  </Button>
                </div>
                {
                  shares.length > 0
                    ? (
                      <div>
                        <UserAvatarGroup size="small">
                          {shares.map(user => {
                            const { id } = user;

                            return (
                              <UserAvatar
                                key={id}
                                user={user}
                              />
                            );
                          })}
                        </UserAvatarGroup>
                      </div>
                    ) : (
                      null
                    )
                }
              </>
            ) : null
        }
        <div>
          <ContactSharingPublicizeButton
            isContactPrivate={isContactPrivate}
            isDisabled={isPublicizeButtonDisabled}
            onClick={onPublicizeButtonClick}
          />
        </div>
      </div>
      <ContactPublicizeConfirmationAlert
        isContactPrivate={isContactPrivate}
        isOpen={isPublicizeConfirmationAlertOpen}
        onClose={onPublicizeConfirmationAlertClose}
        onSubmit={onPublicizeConfirmationAlertSubmit}
      />
      {
        shares
          ? (
            <ContactShareEditor
              shares={shares}
              splitting={splitting}
              contactCreatorId={contactCreatorId}
              isOpen={isShareEditorOpen}
              onClose={onShareEditorClose}
              onSubmit={onShareEditorSubmit}
            />
          ) : null
      }
    </>
  );
};

export default ContactSharing;
