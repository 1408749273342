import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "@cargotic/time";

import {
  makeStyles,
  MenuList,
  Grow,
  TextField,
  Popper,
  InputAdornment,
  ClickAwayListener,
  IconButton,
  Button,
  Paper
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    width: "0.8em",
    height: "0.8em"
  },
  endIcon: {
    marginLeft: 0
  },
  buttonRoot: {
    textTransform: "none",
    fontWeight: 600
  },
  buttonRootSelected: {
    background: "#D6D6D6",
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#D6D6D6"
    }
  },
  text: {
    padding: "4px 6px"
  },
  field: {
    padding: spacing(1)
  },
  adorned: {
    paddingRight: 0
  }
}));

const FilterTextDropdown = ({
  id,
  placeholderTitle,
  onChange,
  value,
  onClear,
  disabled
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const stopPropagation = e => {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      default:
        e.stopPropagation();
    }
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSuggest = useCallback(debounce(1000)(async () => onChange(searchText)));

  const handleClearAndClose = () => {
    onClear();
    handleToggle();
  };

  return (
    <div>
      <Button
        ref={anchorRef}
        classes={{ root: value ? classes.buttonRootSelected : classes.buttonRoot, endIcon: classes.endIcon, text: classes.text }}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple
        disabled={disabled}
        endIcon={
          <>
            <IconButton onClick={() => null} size="small">
              <ArrowDropDownIcon size="small" className={classes.icon} />
            </IconButton>
            {value ?
              (<IconButton onClick={handleClearAndClose} size="small">
                <CloseIcon size="small" className={classes.icon} />
              </IconButton>) : null}
          </>
        }
      >
        {placeholderTitle}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper style={{ maxHeight: 400, overflow: "auto" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  <TextField
                    id="search"
                    fullWidth
                    value={searchText}
                    className={classes.field}
                    label={t("search")}
                    onChange={({ target: { value } }) => {
                      setSearchText(value);
                      handleSuggest(value);
                    }}
                    onKeyDown={stopPropagation}
                    variant="outlined"
                    InputProps={{
                      classes: { adornedEnd: classes.adorned },
                      endAdornment:
                        searchText === "" ? (<InputAdornment position="end"><IconButton><SearchIcon /></IconButton></InputAdornment>) : <InputAdornment position="end">
                          <IconButton onClick={() => {
                            setSearchText("");
                            handleToggle();
                          }}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default FilterTextDropdown;
