import * as Yup from "yup";

import { parsePhoneNumberFromString } from "libphonenumber-js";

const IC_PATTERN = /^\d{8}$/;
const URL_PATTERN = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)$/;

const DEFAULT_COUNTRY_CODE = "CZ";

const ContactSchemaWithoutType = Yup.object().shape({
  companyName: Yup.string().required("company.validation.nameRequired"),
  ic: Yup.string().matches(IC_PATTERN, "company.validation.icoFormat").nullable(),
  dic: Yup.string().required("company.validation.dicRequired"),
  email: Yup.string().email("company.validation.emailValid").nullable(),
  phoneNumber: Yup.string().nullable(),
  website: Yup.string().matches(URL_PATTERN, "company.validation.urlValid").nullable().max(256, "company.validation.urlLength"),
  notes: Yup.string().nullable(),
  billingAddress: Yup.string("contacts.validation.addressText").required("company.validation.addressRequired").nullable(),
  deliveryAddress: Yup.string("contacts.validation.addressText").nullable(),
  paymentDueDays: Yup.number().integer("contacts.validation.dueDays").positive("contacts.validation.dueDays").nullable(),
  billingContact: Yup.string("contacts.validation.billingContact").nullable().max(256, "company.validation.billingContactLength")
});

const ContactSchema = ContactSchemaWithoutType.concat(Yup.object().shape({
  type: Yup.string().required("contacts.validation.typeRequired"),
  visibility: Yup.string().required("contacts.validation.visibilityRequired")
}));

const DispatcherCreateSchema = Yup.object().shape({
  firstName: Yup.string().required("dispatchers.validation.firstNameRequired"),
  lastName: Yup.string().required("dispatchers.validation.lastNameRequired"),
  email: Yup.string().email("dispatchers.validation.emailValid").required("dispatchers.validation.emailRequired"),
  password: Yup.string().required("dispatchers.validation.passwordRequired").min(8, "dispatchers.validation.passwordMin"),
  phoneNumber: Yup.string()
});

const DispatcherUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required("dispatchers.validation.firstNameRequired"),
  lastName: Yup.string().required("dispatchers.validation.lastNameRequired")
});

const VehicleSchema = Yup.object().shape({
  name: Yup.string().required("vehicles.validation.nameRequired"),
  vendor: Yup.string().required("vehicles.validation.vendorRequired"),
  spz: Yup.string().max(8, "vehicles.validation.spzMax").required("vehicles.validation.spzRequired"),
  vin: Yup.string().max(32, "vehicles.validation.vinMax").required("vehicles.validation.vinRequired"),
  fuel: Yup.string().required("vehicles.validation.fuelRequired"),
  width: Yup.number().positive("vehicles.validation.widthPositive").required("vehicles.validation.widthRequired"),
  height: Yup.number().positive("vehicles.validation.heightPositive").required("vehicles.validation.heightRequired"),
  length: Yup.number().positive("vehicles.validation.lengthPositive").required("vehicles.validation.lengthRequired"),
  maxLoad: Yup.number().positive("vehicles.validation.maxLoadPositive").required("vehicles.validation.maxLoadRequired")
});

const PlaceSchema = Yup.object().shape({
  alias: Yup.string().required("places.validation.aliasRequired"),
  address: Yup.string().required("places.validation.addressRequired")
});

const DriverCreateSchema = Yup.object().shape({
  firstName: Yup.string().required("drivers.validation.firstNameRequired"),
  lastName: Yup.string().required("drivers.validation.lastNameRequired"),
  email: Yup.string().email("drivers.validation.emailValid").required("drivers.validation.emailRequired"),
  password: Yup.string().required("drivers.validation.passwordRequired").min(8, "drivers.validation.passwordMin"),
  phoneNumber: Yup.string()
});

const DriverUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required("drivers.validation.firstNameRequired"),
  lastName: Yup.string().required("drivers.validation.lastNameRequired")
});

const ShipmentSchema = Yup.object().shape({
  name: Yup.string().required("shipments.validation.shipmentNameRequired"),
  emailSender: Yup.string().email("shipments.validation.emailValid").nullable(),
  emailReceiver: Yup.string().email("shipments.validation.emailValid").nullable()
});

const OrderSchema = Yup.object().shape({
  departureAddress: Yup.string().min(1).required("orders.validation.departureAddressRequired"),
  deliveryAddress: Yup.string().min(1).required("orders.validation.deliveryAddressRequired"),
  carrier: Yup.string().min(1).required("orders.validation.carrierRequired"),
  customer: Yup.string().min(1).required("orders.validation.customerRequired"),
  carrierPrice: Yup.number().positive("orders.validation.carrierPricePositive").required("orders.validation.carrierPriceRequired"),
  customerPrice: Yup.number().positive("orders.validation.customerPricePositive").required("orders.validation.customerPriceRequired"),
  senderContact: Yup.string().nullable(),
  receiverContact: Yup.string().nullable(),
  dueDate: Yup.number().integer("orders.validation.daysToBePaidPositive").positive("orders.validation.daysToBePaidPositive").required("orders.validation.daysToBePaidRequired"),
  departureDateTimeFrom: Yup.date().required("orders.validation.departureFromRequired"),
  departureDateTimeTo: Yup.date().nullable(),
  deliveryDateTimeFrom: Yup.date().required("orders.validation.deliveryFromRequired"),
  deliveryDateTimeTo: Yup.date().nullable(),
  items: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number()
        .typeError("orders.validation.quantityRequired")
        .min(0, "orders.validation.quantityRequired")
        .nullable()
        .transform((value, originalValue) => (originalValue === "" ? null : value)),
      length: Yup.number()
        .integer("orders.validation.lengthPositive")
        .typeError("orders.validation.lengthPositive")
        .min(0, "orders.validation.lengthPositive")
        .nullable()
        .transform((value, originalValue) => (originalValue === "" ? null : value)),
      width: Yup.number()
        .integer("orders.validation.widthPositive")
        .typeError("orders.validation.widthPositive")
        .min(0, "orders.validation.widthPositive")
        .nullable()
        .transform((value, originalValue) => (originalValue === "" ? null : value)),
      height: Yup.number()
        .integer("orders.validation.heightPositive")
        .typeError("orders.validation.heightPositive")
        .min(0, "orders.validation.heightPositive")
        .nullable()
        .transform((value, originalValue) => (originalValue === "" ? null : value)),
      weight: Yup.number()
        .integer("orders.validation.weightPositive")
        .typeError("orders.validation.weightPositive")
        .min(0, "orders.validation.weightPositive")
        .nullable()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
    })
  )
});

const CompanyProfileSchema = Yup.object().shape({
  companyName: Yup.string().required("company.validation.nameRequired"),
  ic: Yup.string().matches(IC_PATTERN, "company.validation.icoFormat").required("company.validation.icoRequired"),
  dic: Yup.string().required("company.validation.dicRequired"),
  url: Yup.string().matches(URL_PATTERN, "company.validation.urlValid").nullable(),
  address: Yup.string("company.validation.addressRequired").required("company.validation.addressRequired").nullable(),
  email: Yup.string("company.validation.emailValid").email("company.validation.emailValid").nullable(),
  responsiblePerson: Yup.string().required("company.validation.responsiblePersonRequired").nullable(),
  phoneNumber: Yup.string().test("phoneValidation", "company.validation.phone", function (value) {
    if (!value) {
      return true;
    }
    const parsed = parsePhoneNumberFromString(value, DEFAULT_COUNTRY_CODE);
    return parsed?.isValid();
  }).nullable()
});

const CustomerProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("customers.validation.firstNameRequired"),
  lastName: Yup.string().required("customers.validation.lastNameRequired"),
  phoneNumber: Yup.string().test("phoneValidation", "users.error.phone", function (value) {
    if (!value) {
      return true;
    }
    const parsed = parsePhoneNumberFromString(value, DEFAULT_COUNTRY_CODE);
    return parsed?.isValid();
  }).nullable()
});

const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required("auth.validation.passwordRequired").min(8, "auth.validation.passwordMin")
});

const SignInSchema = Yup.object().shape({
  email: Yup.string().email("auth.validation.emailValid").required("auth.validation.emailRequired"),
  password: Yup.string().required("auth.validation.passwordRequired").min(7, "auth.validation.passwordMin"),
  remember: Yup.boolean()
});

const SignUpUserSchema = Yup.object().shape({
  firstName: Yup.string().required("auth.validation.firstNameRequired"),
  lastName: Yup.string().required("auth.validation.lastNameRequired"),
  email: Yup.string().email("auth.validation.emailValid").required("auth.validation.emailRequired"),
  password: Yup.string().required("auth.validation.passwordRequired").min(8, "auth.validation.passwordMin"),
  reenterPassword: Yup.string().required("auth.validation.reenterPassword")
});

const SignUpCompanySchema = Yup.object().shape({
  companyName: Yup.string().required("auth.validation.companyNameRequired"),
  ic: Yup.string().matches(IC_PATTERN, "auth.validation.icoNumber").required("auth.validation.icoRequired"),
  dic: Yup.string().min(4, "auth.validation.dicMin").max(15, "auth.validation.dicMax").required("auth.validation.dicRequired"),
  gdpr: Yup.boolean().oneOf([true], "auth.validation.gdpr").required("auth.validation.gdpr")
});

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("auth.validation.emailValid").required("auth.validation.emailRequired")
});

const TermsSchema = Yup.object().shape({
  terms: Yup.string().nullable(),
  language: Yup.string()
});

const SetPriceSchema = Yup.object().shape({
  price: Yup.number("settings.validation.number").required("settings.validation.priceRequired").positive("settings.validation.positive")
});

const PaidAtSchema = Yup.object().shape({
  issuedInvoiceNumber: Yup.string().max(16, "shipments.validation.invoiceNumberValid").nullable(),
  issuedInvoiceDueDate: Yup.date("shipments.validation.dateValid").nullable(),
  issuedInvoicePaidAt: Yup.date("shipments.validation.dateValid").nullable(),
  receivedInvoiceDueDate: Yup.date("shipments.validation.dateValid").nullable(),
  receivedInvoiceNumber: Yup.string().max(16, "shipments.validation.invoiceNumberValid").nullable(),
  receivedInvoicePaidAt: Yup.date("shipments.validation.dateValid").nullable(),
  taxableSupplyDate: Yup.date("shipments.validation.dateValid").nullable(),
  issueDate: Yup.date("shipments.validation.dateValid").nullable()
});

const SendOrderSchema = Yup.object().shape({
  email: Yup.string().email("users.validation.validEmail").required("users.validation.emailRequired"),
  message: Yup.string().nullable(),
  language: Yup.string(),
  isSendCopyToMeChecked: Yup.boolean()
});

const UserInviteSchema = Yup.object().shape({
  firstName: Yup.string().required("users.validation.firstNameRequired"),
  lastName: Yup.string().required("users.validation.lastNameRequired"),
  email: Yup.string().email("users.validation.validEmail").required("users.validation.emailRequired"),
  role: Yup.string().required("users.validation.roleRequired")
});

const FeedbackSchema = Yup.object().shape({
  feedback: Yup.string().required("feedback.validation.required")
})

export {
  ContactSchema,
  ContactSchemaWithoutType,
  DispatcherCreateSchema,
  DispatcherUpdateSchema,
  VehicleSchema,
  PlaceSchema,
  TermsSchema,
  DriverCreateSchema,
  DriverUpdateSchema,
  ShipmentSchema,
  OrderSchema,
  CompanyProfileSchema,
  CustomerProfileSchema,
  ChangePasswordSchema,
  SignInSchema,
  SignUpUserSchema,
  ResetPasswordSchema,
  SignUpCompanySchema,
  SetPriceSchema,
  UserInviteSchema,
  PaidAtSchema,
  FeedbackSchema,
  SendOrderSchema
};
