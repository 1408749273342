import React, { useState, useEffect, useRef } from "react";
import { cs, enUS } from "date-fns/locale";
import { formatDistance } from "date-fns";

import { useTranslation } from "react-i18next";

import {
  deserializeFloat,
  deserializeInteger,
  identity,
  pipe,
  EditableTypography,
  ValueError
} from "@cargotic/common-deprecated";

import { ShipmentType, ShipmentState } from "@cargotic/model-deprecated";

import { Currency, convertCurrency } from "@cargotic/currency-deprecated";
import { useApiClient } from "@cargotic/api-client-deprecated";
import { allowEmptyString, required, isObject, validateStringLength, validateDateString } from "@cargotic/validate-deprecated";
import {
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Assignment, LocalShipping } from "@material-ui/icons";

import ShipmentSummary from "../ShipmentSummary";
import ShipmentForwarderForm from "../ShipmentForwarderForm";
import ShipmentCarrierForm from "../ShipmentCarrierForm";

import ShipmentFormType from "../../../utility/ShipmentFormType";
import { createFormValidator, useForm } from "../../../form";

import { generateUuid } from "../../../../../multiload/cargotic-common";

const SHIPMENT_INDEX_NUMBER_SHIPMENT_LENGTH = 15;

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    padding: spacing(2)
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",

    "& > div > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > div > button": {
      minWidth: spacing(14)
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: {
    display: "flex"
  },
  selectState: {
    marginRight: spacing(1),
    minWidth: 100
  }
}));

const ShipmentForm = ({
  className,
  apiClient,
  newApiClient,
  exchangeRates,
  journey,
  shipment: {
    indexNumber,
    orderSerialNumber: initialOrderSerialNumber,
    type,
    isDraft,
    state: initialState,
    commissionCurrency = Currency.CZK,
    customerContact: initialCustomerContact = "",
    customerPaymentDueDays: initialCustomerPaymentDueDays = "",
    customerPrice: initialCustomerPrice = "",
    customerPriceCurrency: initialCustomerPriceCurrency = Currency.CZK,
    customerEmployee: initialCustomerEmployee = "",
    customerPosition: initialCustomerPosition = "",
    isCustomerPriceWithDph: initialIsCustomerPriceWithDph,
    carrierContact: initialCarrierContact = "",
    driverContact: initialDriverContact,
    carrierPaymentDueDays: initialCarrierPaymentDueDays = "",
    carrierPrice: initialCarrierPrice = "",
    carrierPriceCurrency: initialCarrierPriceCurrency = Currency.CZK,
    carrierEmployee: initialCarrierEmployee = "",
    isCarrierPriceWithDph: initialIsCarrierPriceWithDph,
    driver: initialDriver = "",
    vehicle: initialVehicle = "",
    trailer: initialTrailer = "",
    notes: initialNotes,
    terms: initialTerms,
    issuedInvoiceNumber: initialIssuedInvoiceNumber = "",
    issuedInvoiceDueDate: initialIssuedInvoiceDueDate = "",
    issuedInvoicePaidAt: initialIssuedInvoicePaidAt = "",
    receivedInvoiceNumber: initialReceivedInvoiceNumber = "",
    receivedInvoiceDueDate: initialReceivedInvoiceDueDate = "",
    receivedInvoicePaidAt: initialReceivedInvoicePaidAt = "",
    documents: initialDocuments,
    ...shipment
  },
  isExtended,
  isUpdating,
  availableTerms,
  onBack,
  onFormChange,
  onSave
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = t("locale") === "cs" ? cs : enUS;
  const isMounted = useRef(false);
  const [formType, setFormType] = useState(
    type === ShipmentType.CARRIED ? ShipmentFormType.CARRIER : ShipmentFormType.FORWARDER
  );
  const [state, setState] = useState(initialState || ShipmentState.QUEUE);
  const [stateChange, setStateChange] = useState(false);
  const [
    isOrderSerialNumberDuplicate,
    setIsOrderSerialNumberDuplicate
  ] = useState(false);

  const [shipmentNumber, setShipmentNumber] = useState(indexNumber);

  const validateDeleted = (x, message = "") => {
    if (typeof x === "object" && x?.isDeleted) {
      throw new ValueError(message);
    }

    return x;
  };

  const validateContact = pipe(
    (contact) => required(contact, t("webapp:shipment.form.error.contact")),
    (contact) => isObject(contact, t("webapp:shipment.form.error.contact")),
    (contact) => validateDeleted(contact, t("webapp:shipment.form.error.deletedContact"))
  );

  const validateTerms = pipe(
    (terms) => required(terms, t("webapp:shipment.form.error.contact"))
  );

  const validateOrderSerialNumber = pipe(
    (value) => required(value || undefined, t("webapp:shipment.form.error.orderSerialNumber")),
    (value) => validateStringLength(value, 16, t("webapp:shipment.form.error.orderSerialNumberLength"))
  );

  const validateDraftContact = pipe(
    allowEmptyString((contact) => isObject(contact, t("webapp:shipment.form.error.contact"))),
    (contact) => validateDeleted(contact, t("webapp:shipment.form.error.deletedContact"))
  );

  const validateEmployee = pipe(
    allowEmptyString((employee) => isObject(employee, t("webapp:shipment.form.error.employee"))),
    (employee) => validateDeleted(employee, t("webapp:shipment.form.error.deletedEmployee"))
  );

  const isString = x => typeof x === "string";
  const validateDraftPaymentDueDays = allowEmptyString(
    (x) => deserializeInteger(x, 10, t("webapp:shipment.form.error.paymentDueDays"))
  );

  const validateParsedDate = allowEmptyString(
    (x) => validateDateString(x, t("webapp:shipment.form.error.date"))
  );

  const validateDraftPrice = allowEmptyString(
    (x) => deserializeFloat(x, t("webapp:shipment.form.error.price"))
  );

  const validateDriver = pipe(
    (driver) => required(driver, t("webapp:shipment.form.error.driver")),
    (driver) => isObject(driver, t("webapp:shipment.form.error.driver")),
    (driver) => validateDeleted(driver, t("webapp:shipment.form.error.deletedDriver"))
  );

  const validateDraftDriver = pipe(
    allowEmptyString((driver) => isObject(driver, t("webapp:shipment.form.error.driver"))),
    (driver) => validateDeleted(driver, t("webapp:shipment.form.error.deletedDriver"))
  )

  const validateVehicle = pipe(
    (vehicle) => required(vehicle, t("webapp:shipment.form.error.vehicle")),
    (vehicle) => isObject(vehicle, t("webapp:shipment.form.error.vehicle")),
    (vehicle) => validateDeleted(vehicle, t("webapp:shipment.form.error.deletedVehicle"))
  );

  const validateDraftVehicle = pipe(
    allowEmptyString((vehicle) => isObject(vehicle, t("webapp:shipment.form.error.vehicle"))),
    (vehicle) => validateDeleted(vehicle, t("webapp:shipment.form.error.deletedVehicle"))
  )

  const validateTrailer = pipe(
    allowEmptyString((trailer) => isObject(trailer, t("webapp:shipment.form.error.trailer"))),
    (trailer) => validateDeleted(trailer, t("webapp:shipment.form.error.deletedTrailer"))
  )

  const validateCustomerPaymentDueDays = pipe(
    (x) => required(x, t("webapp:shipment.form.error.paymentDueDays")),
    (x) => deserializeInteger(x, 10, t("webapp:shipment.form.error.paymentDueDays"))
  );

  const validateCarrierPaymentDueDays = allowEmptyString(
    (x) => deserializeInteger(x, 10, t("webapp:shipment.form.error.paymentDueDays"))
  );

  const validatePosition = allowEmptyString(
    (x) => validateStringLength(x, 32, t("webapp:shipment.form.error.customerPositionTooLong"))
  );

  const validatePrice = pipe(
    (x) => required(x, t("webapp:shipment.form.error.price")),
    (x) => deserializeFloat(x, t("webapp:shipment.form.error.price"))
  );

  const validateInvoiceNumber = allowEmptyString(
    (x) => validateStringLength(x, 16, t("webapp:shipment.form.error.invoiceNumberTooLong"))
  );

  const validateOptionalString = allowEmptyString(identity);

  const calculateCommission = (
    customerPrice,
    customerPriceCurrency,
    carrierPrice,
    carrierPriceCurrency,
    commissionCurrency
  ) => {
    const parsedCustomerPrice = parseFloat(customerPrice);
    const parsedCarrierPrice = parseFloat(carrierPrice);

    if (
      Number.isNaN(parsedCustomerPrice)
      || Number.isNaN(parsedCarrierPrice)
    ) {
      return undefined;
    }

    const convertedCustomerPrice = convertCurrency(
      parsedCustomerPrice,
      customerPriceCurrency,
      commissionCurrency,
      exchangeRates
    );

    const convertedCarrierPrice = convertCurrency(
      parsedCarrierPrice,
      carrierPriceCurrency,
      commissionCurrency,
      exchangeRates
    );

    return convertedCustomerPrice - convertedCarrierPrice;
  };

  const validateSubmitDraft = ({ values }) => {
    let errors = {};

    // validate shared values for both forms
    try {
      validateDraftContact(values.customerContact);
    } catch (err) {
      errors = { ...errors, customerContact: err };
    }
    try {
      validateEmployee(values.customerEmployee);
    } catch (err) {
      errors = { ...errors, customerEmployee: err };
    }
    try {
      validateDraftPaymentDueDays(values.customerPaymentDueDays);
    } catch (err) {
      errors = { ...errors, customerPaymentDueDays: err };
    }
    try {
      validateDraftPrice(values.customerPrice);
    } catch (err) {
      errors = { ...errors, customerPrice: err };
    }
    try {
      validatePosition(values.customerPosition);
    } catch (err) {
      errors = { ...errors, customerPosition: err };
    }

    try {
      validateInvoiceNumber(values.issuedInvoiceNumber);
    } catch (err) {
      errors = { ...errors, issuedInvoiceNumber: err };
    }

    try {
      validateParsedDate(values.issuedInvoiceDueDate);
    } catch (err) {
      errors = { ...errors, issuedInvoiceDueDate: err };
    }

    try {
      validateParsedDate(values.issuedInvoicePaidAt);
    } catch (err) {
      errors = { ...errors, issuedInvoicePaidAt: err }
    }

    try {
      validateParsedDate(values.receivedInvoiceDueDate);
    } catch (err) {
      errors = { ...errors, receivedInvoiceDueDate: err };
    }

    try {
      validateParsedDate(values.receivedInvoicePaidAt);
    } catch (err) {
      errors = { ...errors, receivedInvoicePaidAt: err }
    }

    try {
      validateInvoiceNumber(values.receivedInvoiceNumber);
    } catch (err) {
      errors = { ...errors, receivedInvoiceNumber: err };
    }
    // validate forwarder part
    if (formType === ShipmentFormType.FORWARDER) {
      try {
        validateDraftContact(values.carrierContact);
      } catch (err) {
        errors = { ...errors, carrierContact: err };
      }
      try {
        validateEmployee(values.carrierEmployee);
      } catch (err) {
        errors = { ...errors, carrierEmployee: err };
      }
      try {
        validateDraftPaymentDueDays(values.customerPaymentDueDays);
      } catch (err) {
        errors = { ...errors, carrierPaymentDueDays: err };
      }
      try {
        validateDraftPrice(values.carrierPrice);
      } catch (err) {
        errors = { ...errors, carrierPrice: err };
      }
      try {
        validateOrderSerialNumber(values.orderSerialNumber);
      } catch (err) {
        errors = { ...errors, orderSerialNumber: err };
      }
    }
    else {
      try {
        validateDraftDriver(values.driver)
      } catch (err) {
        errors = { ...errors, driver: err }
      }
      try {
        validateDraftVehicle(values.vehicle);
      } catch (err) {
        errors = { ...errors, vehicle: err }
      }
      try {
        validateTrailer(values.trailer);
      } catch (err) {
        errors = { ...errors, trailer: err };
      }
    }

    scrollOnError(errors);
    return errors;
  };

  const onSubmitDraft = (form) => {
    const { values } = form;
    const errs = validateSubmitDraft(form);

    if (Object.keys(errs).length !== 0) {
      form.setErrors(errs);
      form.touchAll();
      return;
    }
    form.setIsSubmitting(true);

    onSave({
      ...shipment,
      ...values,
      customerPrice: values.customerPrice === "" ? undefined : values.customerPrice,
      carrierPrice: values.carrierPrice === "" ? undefined : values.carrierPrice,
      customerPaymentDueDays: values.customerPaymentDueDays === "" ? undefined : values.customerPaymentDueDays,
      carrierPaymentDueDays: values.carrierPaymentDueDays === "" ? undefined : values.carrierPaymentDueDays,
      isDraft: true,
      state: ShipmentState.QUEUE,
      type: formType === ShipmentFormType.CARRIER ? ShipmentType.CARRIED : ShipmentType.FORWARDED,
      indexNumber: undefined,
      commission: undefined,
      commissionCurrency: undefined,
      documents: values.documents
        .filter(({ id }) => id)
        .map(({ id }) => ({ id }))
    }, form.setIsSubmitting);
  };

  const carrierForm = useForm({
    initialValues: {
      customerContact: initialCustomerContact,
      customerPaymentDueDays: initialCustomerPaymentDueDays,
      customerPrice: initialCustomerPrice,
      customerPriceCurrency: initialCustomerPriceCurrency,
      customerEmployee: initialCustomerEmployee,
      customerPosition: initialCustomerPosition,
      isCustomerPriceWithDph: initialIsCustomerPriceWithDph,
      driver: initialDriver,
      vehicle: initialVehicle,
      trailer: initialTrailer,
      type: ShipmentType.CARRIED,
      notes: initialNotes,
      issuedInvoiceNumber: initialIssuedInvoiceNumber,
      issuedInvoiceDueDate: initialIssuedInvoiceDueDate,
      issuedInvoicePaidAt: initialIssuedInvoicePaidAt,
      documents: initialDocuments
        .map((document) => ({ ...document, uuid: generateUuid() }))
    },
    validate: createFormValidator({
      customerContact: validateContact,
      customerEmployee: validateEmployee,
      customerPaymentDueDays: validateDraftPaymentDueDays,
      customerPosition: validatePosition,
      customerPrice: validatePrice,
      driver: validateDriver,
      vehicle: validateVehicle,
      trailer: validateTrailer,
      notes: validateOptionalString,
      issuedInvoiceNumber: validateInvoiceNumber,
      issuedInvoiceDueDate: validateParsedDate,
      issuedInvoicePaidAt: validateParsedDate
    }),
    onChange: (shipment, oldShipment) => {
      const {
        customerPrice,
        customerContact: newCustomerContact,
        currency: newCurrency
      } = shipment;
      const {
        customerContact: oldCustomerContact
      } = oldShipment;

      if (newCustomerContact !== oldCustomerContact) {
        carrierForm.setValue("customerContact", newCustomerContact);
      }
    },
    onSubmit: (values) => {
      onSave({
        ...shipment,
        ...values,
        orderSerialNumber: undefined,
        customerPrice: values.customerPrice === "" ? undefined : values.customerPrice,
        carrierPrice: values.carrierPrice === "" ? undefined : values.carrierPrice,
        indexNumber: shipmentNumber,
        commission: undefined,
        commissionCurrency: undefined,
        isDraft: false,
        state,
        documents: values.documents
          .filter(({ id }) => id)
          .map(({ id }) => ({ id }))
      }, carrierForm.setIsSubmitting);
    }
  });

  const forwarderForm = useForm({
    initialValues: {
      orderSerialNumber: initialOrderSerialNumber,
      customerContact: initialCustomerContact,
      customerPaymentDueDays: initialCustomerPaymentDueDays,
      customerPrice: initialCustomerPrice,
      customerPriceCurrency: initialCustomerPriceCurrency,
      customerEmployee: initialCustomerEmployee,
      customerPosition: initialCustomerPosition,
      isCustomerPriceWithDph: initialIsCustomerPriceWithDph,
      carrierContact: initialCarrierContact,
      driverContact: initialDriverContact,
      carrierPaymentDueDays: initialCarrierPaymentDueDays,
      carrierPrice: initialCarrierPrice,
      carrierPriceCurrency: initialCarrierPriceCurrency,
      carrierEmployee: initialCarrierEmployee,
      isCarrierPriceWithDph: initialIsCarrierPriceWithDph,
      notes: initialNotes,
      terms: initialTerms,
      issuedInvoiceNumber: initialIssuedInvoiceNumber,
      issuedInvoiceDueDate: initialIssuedInvoiceDueDate,
      issuedInvoicePaidAt: initialIssuedInvoicePaidAt,
      receivedInvoiceNumber: initialReceivedInvoiceNumber,
      receivedInvoiceDueDate: initialReceivedInvoiceDueDate,
      receivedInvoicePaidAt: initialReceivedInvoicePaidAt,
      type: ShipmentType.FORWARDED,
      documents: initialDocuments
        .map((document) => ({ ...document, uuid: generateUuid() }))
    },
    validate: createFormValidator({
      orderSerialNumber: validateOrderSerialNumber,
      driverContact: validateOptionalString,
      customerContact: validateContact,
      customerEmployee: validateEmployee,
      customerPaymentDueDays: validateCustomerPaymentDueDays,
      customerPosition: validatePosition,
      customerPrice: validatePrice,
      carrierContact: validateContact,
      carrierPaymentDueDays: validateCarrierPaymentDueDays,
      carrierPrice: validatePrice,
      carrierEmployee: validateEmployee,
      notes: validateOptionalString,
      terms: validateTerms,
      issuedInvoiceNumber: validateInvoiceNumber,
      receivedInvoiceNumber: validateInvoiceNumber,
      issuedInvoiceDueDate: validateParsedDate,
      issuedInvoicePaidAt: validateParsedDate,
      receivedInvoiceDueDate: validateParsedDate,
      receivedInvoicePaidAt: validateParsedDate
    }),
    onChange: (shipment, oldShipment) => {
      const {
        orderSerialNumber,
        customerPrice,
        carrierPrice,
        carrierContact: newCarrierContact,
        customerContact: newCustomerContact,
        customerPriceCurrency: newCustomerPriceCurrency,
        carrierPriceCurrency: newCarrierPriceCurrency
      } = shipment;
      const {
        carrierContact: oldCarrierContact,
        customerContact: oldCustomerContact,
        customerPriceCurrency: oldCustomerPriceCurrency,
        carrierPriceCurrency: oldCarrierPriceCurrency
      } = oldShipment;

      if (newCarrierContact !== oldCarrierContact) {
        forwarderForm.setValue("carrierContact", newCarrierContact);
      }

      if (newCustomerContact !== oldCustomerContact) {
        forwarderForm.setValue("customerContact", newCustomerContact);
      }
    },
    onSubmit: (values) => {
      onSave({
        ...shipment,
        ...values,
        orderSerialNumber: values.orderSerialNumber,
        customerPrice: values.customerPrice === "" ? undefined : values.customerPrice,
        carrierPrice: values.carrierPrice === "" ? undefined : values.carrierPrice,
        customerPaymentDueDays: values.customerPaymentDueDays === "" ? undefined : values.customerPaymentDueDays,
        carrierPaymentDueDays: values.carrierPaymentDueDays === "" ? undefined : values.carrierPaymentDueDays,
        state,
        indexNumber: shipmentNumber,
        commission: calculateCommission(
          forwarderForm.values.customerPrice,
          forwarderForm.values.customerPriceCurrency,
          forwarderForm.values.carrierPrice,
          forwarderForm.values.carrierPriceCurrency,
          commissionCurrency
        ),
        commissionCurrency,
        isDraft: false,
        documents: values.documents
          .filter(({ id }) => id)
          .map(({ id }) => ({ id }))
      }, forwarderForm.setIsSubmitting);
    }
  });

  const calulatedCommission = calculateCommission(
    forwarderForm.values.customerPrice,
    forwarderForm.values.customerPriceCurrency,
    forwarderForm.values.carrierPrice,
    forwarderForm.values.carrierPriceCurrency,
    commissionCurrency
  );

  const commission = formType === ShipmentFormType.FORWARDER
    && calulatedCommission !== undefined
    ? `${calulatedCommission.toFixed(2)} ${commissionCurrency}`
    : t("webapp:shipment.summary.metric.unknown");

  const handleToggleChange = (_, value) =>
    value ? setFormType(value) : undefined;

  const handleShipmentNumberChange = ({ target: { value } }) =>
    setShipmentNumber(value);

  const scrollOnError = (errors) => {
    if (errors && Object.keys(errors).length > 0) {
      const elements = document.getElementsByName(Object.keys(errors)[0]);
      if (elements.length > 0) {
        elements[0].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  const handleSubmit = (event) => {
    const errors = formType === ShipmentFormType.CARRIER
      ? carrierForm.handleSubmit(event)
      : forwarderForm.handleSubmit(event);
    scrollOnError(errors);
  };

  const handleState = ({ target: { value } }) => {
    setState(value);
    setStateChange(true);
  };

  useEffect(() => {
    if (stateChange) {
      return;
    }
    const { errors } = formType === ShipmentFormType.FORWARDER ? forwarderForm : carrierForm;
    if (Object.keys(errors).length === 0) {
      if (!isUpdating) {
        setState(ShipmentState.READY);
      } else {
        setState(initialState || ShipmentState.READY);
      }
    } else {
      setState(ShipmentState.QUEUE);
    }
  }, [formType === ShipmentFormType.FORWARDER ? forwarderForm.errors : carrierForm.errors]);

  useEffect(() => {
    onFormChange(forwarderForm.values);
  }, [forwarderForm.values]);

  useEffect(() => {
    onFormChange(carrierForm.values);
  }, [carrierForm.values]);

  useEffect(() => {
    (async () => {
      const duplicate = await newApiClient.shipment.getShipmentDuplicate({
        orderSerialNumber: forwarderForm.values.orderSerialNumber
      });

      if (duplicate && duplicate.id !== shipment.id) {
        setIsOrderSerialNumberDuplicate(true);
      } else {
        setIsOrderSerialNumberDuplicate(false);
      }
    })();
  }, [forwarderForm.values.orderSerialNumber]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    forwarderForm.setValues({
      ...forwarderForm.values,
      orderSerialNumber: shipmentNumber
    });
  }, [shipmentNumber]);


  return (
    <form className={className} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid className={classes.header} item xs={8}>
          <section className={classes.title}>
            <Typography variant="h4">
              {`${t("webapp:shipment.form.title")} #`}
            </Typography>
            <EditableTypography
              variant="h4"
              maxLength={SHIPMENT_INDEX_NUMBER_SHIPMENT_LENGTH}
              value={shipmentNumber}
              isDisabled={isUpdating}
              onChange={handleShipmentNumberChange}
              dataCy="shipment-id"
            />
          </section>
          <section style={{ display: "flex" }}>
            <Select
              autoWidth
              onChange={handleState}
              disableUnderline
              variant="outlined"
              className={classes.selectState}
              value={state}
            >
              <MenuItem value={ShipmentState.QUEUE}>{t("board.state.queue")}</MenuItem>
              <MenuItem value={ShipmentState.READY}>{t("board.state.ready")}</MenuItem>
              <MenuItem value={ShipmentState.IN_PROGRESS}>{t("board.state.inProgress")}</MenuItem>
              <MenuItem value={ShipmentState.DONE}>{t("board.state.done")}</MenuItem>
            </Select>
            <ToggleButtonGroup
              value={formType}
              onChange={handleToggleChange}
              exclusive
            >
              <ToggleButton value={ShipmentFormType.FORWARDER}>
                <Tooltip title={t("webapp:shipment.form.tooltip.forward")}>
                  <Assignment />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={ShipmentFormType.CARRIER} disabled={!isExtended}>
                <Tooltip title={t("webapp:shipment.form.tooltip.ship")}>
                  <LocalShipping />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </section>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <Paper className={classes.content}>
            {
              formType === ShipmentFormType.FORWARDER
                ? (
                  <ShipmentForwarderForm
                    form={forwarderForm}
                    isOrderSerialNumberDuplicate={isOrderSerialNumberDuplicate}
                    onDocumentsChange={() => onFormChange(forwarderForm.values)}
                    apiClient={apiClient}
                    newApiClient={newApiClient}
                    initialCarrierPaymentDueDays={initialCarrierPaymentDueDays}
                    initialCustomerPaymentDueDays={initialCustomerPaymentDueDays}
                    availableTerms={availableTerms}
                  />
                )
                : (
                  <ShipmentCarrierForm
                    form={carrierForm}
                    onDocumentsChange={() => onFormChange(carrierForm.values)}
                    apiClient={apiClient}
                    newApiClient={newApiClient}
                    initialCustomerPaymentDueDays={initialCustomerPaymentDueDays}
                  />
                )
            }
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <ShipmentSummary
            commission={commission}
            distance={journey && journey.distance ? (journey.distance / 1000).toFixed(2) + " km" : t("webapp:shipment.summary.metric.unknown")}
            duration={journey && journey.duration ? formatDistance(new Date(journey.duration * 1000), new Date(0), { locale }) : t("webapp:shipment.summary.metric.unknown")}
          />
        </Grid>
        <Grid className={classes.buttons} item xs={8}>
          <div>
            <Button variant="outlined" color="primary" onClick={onBack}>
              {t("webapp:shipment.form.button.back")}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              disabled={!isDraft || (formType === ShipmentFormType.FORWARDER ? forwarderForm.isSubmitting : carrierForm.isSubmitting)}
              onClick={() => onSubmitDraft(formType === ShipmentFormType.CARRIER ? carrierForm : forwarderForm)}
            >
              {t("webapp:shipment.form.button.draft")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              data-cy="complete-shipment"
              disabled={formType === ShipmentFormType.FORWARDER ? forwarderForm.isSubmitting : carrierForm.isSubmitting}
            >
              {t("webapp:shipment.form.button.complete")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default ShipmentForm;
